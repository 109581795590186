import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { useListEventsQuery } from 'gql';
import { useWindowSize } from 'hooks';
import usePagination from 'hooks/usePagination';
import { useParams } from 'react-router-dom';

import EventColumn from './components/EventColumn';

function useEvents() {
  const { extrinsicId } = useParams();
  const { sessionKey } = useApiAccess();
  const { isMobile } = useWindowSize();

  const { PageController, setTotalDataCount, pageSize, pageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });
  const { data, loading: isLoading } = useListEventsQuery({
    variables: {
      extrinsicId: extrinsicId as string,
      page: {
        number: pageNumber,
        size: pageSize,
      },
      sessionKey,
    },
    onCompleted(data) {
      setTotalDataCount(data.listEvents.count);
    },
  });
  const COLUMNS = EventColumn({ isMobile });

  return {
    PageController,
    eventsData: data?.listEvents.data,
    eventsCount: data?.listEvents.count,
    isLoading,
    pageSize,
    COLUMNS,
  };
}

export default useEvents;

import { Tooltip } from '@analog/ui/src';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import allImgPaths from 'assets/allImgPaths';
import { IconCard, Text } from 'components';
import { Skeleton } from 'components/Skeleton';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';
import { classes, getIcon } from 'utils';

const transformToNumber = (td: string) => Number(td).toFixed(2);

const ValidatorColumn = () => {
  return [
    {
      title: 'Rank',
      key: 'rank',
      render(data: ColumnData) {
        return (
          <Skeleton.Loader className="w-[30px] h-4" containerClassName="flex">
            <Text extraClass="text-white text-sm pr-1">{data[data.columnKey]}</Text>
          </Skeleton.Loader>
        );
      },
    },
    {
      title: 'Name',
      key: 'name',
      render(data: ColumnData) {
        return (
          <Text extraClass="text-white text-sm pr-1">{data[data.columnKey] || 'Analog User'}</Text>
        );
      },
    },
    {
      title: 'Address',
      key: 'address',
      render(data: ColumnData) {
        return (
          <Link
            className="flex gap-[10px] col-span-2 md:col-span-1 cursor-pointer items-center"
            to={`${routes.validator}/${data[data.columnKey]}`}
            state={{ data: data }}
          >
            <IconCard icon={allImgPaths.addressIcon} />
            <div className={'flex flex-col overflow-hidden w-full'}>
              <TableColumns
                value={data[data.columnKey]}
                withTruncate
                withCopyButton
                textColor={FontColors.PURPLE}
              />
            </div>
          </Link>
        );
      },
    },
    {
      title: 'Discovered',
      key: 'discovered',
      render(data: ColumnData) {
        return (
          <Text extraClass="text-white text-sm">
            {moment(Number(data[data.columnKey])).fromNow()}
          </Text>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      render(data: ColumnData) {
        return (
          <Skeleton.Loader className="w-20 h-4" containerClassName="flex">
            <TableColumns
              value={data[data.columnKey]}
              beforeImagePath={data[data.columnKey] ? getIcon(data[data.columnKey]) : '-'}
              classNames={{ beforeIcon: 'h-[9px] w-[9px]', content: 'capitalize text-white' }}
            />
          </Skeleton.Loader>
        );
      },
    },
    {
      title: 'Valid',
      key: 'valid',
      render(data: ColumnData) {
        return (
          <div
            className={classes(
              'flex items-center gap-1',
              !data[data.columnKey] ? 'text-red-500' : ''
            )}
          >
            <Skeleton.Loader className="w-[30px] h-4" containerClassName="flex">
              {
                <div
                  className="relative flex items-center gap-1 text-sm cursor-default"
                  {...(!data[data.columnKey] && {
                    'data-tooltip-id': `invalid-tooltip-${data.address}`,
                  })}
                >
                  <Skeleton.Loader className="w-7">
                    {!data[data.columnKey] && <InformationCircleIcon width={16} stroke="#EF4444" />}
                    {data[data.columnKey] ? 'Valid' : 'Invalid'}
                  </Skeleton.Loader>
                </div>
              }
            </Skeleton.Loader>
            <Tooltip
              id={`invalid-tooltip-${data.address}`}
              content={data.inValidReason}
              classNameArrow="!border-none"
              rest={{ arrowColor: '#232224' }}
            />
          </div>
        );
      },
    },
    {
      title: 'Faults',
      key: 'faults',
      render(data: ColumnData) {
        return (
          <Text
            extraClass={classes(
              'text-white text-sm',
              Number(data[data.columnKey]) > 0 ? 'text-red-600' : ''
            )}
          >
            <Skeleton.Loader className="w-[40px] h-5">{data[data.columnKey]}</Skeleton.Loader>
          </Text>
        );
      },
    },
    {
      title: 'Inclusion',
      key: 'inclusion',
      render(data: ColumnData) {
        return (
          <Text extraClass="text-white text-sm">{transformToNumber(data[data.columnKey])}</Text>
        );
      },
    },
    {
      title: 'Score',
      key: 'score',
      render(data: ColumnData) {
        return (
          <Text extraClass="text-white text-sm">{transformToNumber(data[data.columnKey])}</Text>
        );
      },
    },
    {
      title: 'Commission',
      key: 'commission',
      render(data: ColumnData) {
        return (
          <Text extraClass="text-white text-sm">{transformToNumber(data[data.columnKey])} %</Text>
        );
      },
    },
    {
      title: 'Rewards',
      key: 'rewards',
      render(data: ColumnData) {
        return (
          <Skeleton.Loader className="flex md:justify-end" containerClassName="flex">
            <Text extraClass="text-white text-sm flex">
              {transformToNumber(data[data.columnKey])} TANLOG
            </Text>
          </Skeleton.Loader>
        );
      },
    },
  ];
};
export default ValidatorColumn;

import { useState } from 'react';

import { SortOrderType } from 'gql';

function useSortBy() {
  const [sortField, setSortField] = useState<string>();
  const [sortOrder, setSortOrder] = useState<SortOrderType>();

  function onSort(field?: string) {
    setSortField(field);

    setSortOrder((prev) => {
      switch (prev) {
        case SortOrderType.Asc:
          return field === sortField ? SortOrderType.Desc : SortOrderType.Asc;
        case SortOrderType.Desc:
          if (field === sortField) {
            setSortField(undefined);
            return undefined;
          } else {
            return SortOrderType.Asc;
          }
        default:
          return SortOrderType.Asc;
      }
    });
  }

  return { sortField, sortOrder, onSort };
}

export default useSortBy;

export const FAQ_DATA = [
  {
    title: '1. How long does a typical cross-chain transaction take?',
    content: "The time to finality — or block time — in the context of the GMP protocol largely depends on the block finality of the interconnected chains as well as that of the Timechain.However, our goal at Analog is to minimize the Timechain’s block finality.This means that only the source chain and destination’s block finality will be the main factors that determine the end- to - end elapsed time for GMP protocol to send a message from one chain to another.",
  },
  {
    title: '2. What is block finality?',
    content:
      'Block finality is a state where a transaction or a block is considered irrevocable or permanent once it has been validated by blockchain nodes. It implies a state at which consensus has been reached within the blockchain network, confirming that a particular transaction or block is indeed valid and will not be reversed in the future.',
  },
  {
    title:
      '3. What categories of nodes operate as validators on the Analog Network and what are their roles?',
    content: `Analog Network has two primary sets of validators: Chronicle Nodes and Time Nodes. Chronicle Nodes are entities that listen to inbound cross-chain requests from supported chains, attest to their validity, and forward them to the Timechain. They also serve as relayers by forwarding the validated GMP messages to the destination chains and executing them. 
    \n
      On the other hand, Time Nodes are entities that secure the Timechain by participating in an NPoS consensus algorithm. Once the source chain emits an event and Chronicle Nodes attest to the cross-chain request, each Time Node participates in an NPoS consensus process that further validates and adds the GMP message to the Timechain.`,
  },
  {
    title: '4. How can I track GMP messages on Analog Explorer?',
    content:
      'After sending your transaction to the source chain, you should be able to track its status in the Analog Explorer. You can use block number, extrinsic/transaction hash, account address, task ID, or shard ID to track the execution status of your message. If everything goes well, the GMP message should be confirmed in the status tab. You can also explore the GMP message’s block time, extrinsics root, hash, and parent hash.',
  },
  {
    title: '5. What is a shard ID?',
    content:
      'Analog Network uses a sharding concept where the chronicle nodes are partitioned into independent subnetworks called shards to enhance multi-chain operability and scalability. The Timechain serves as a record for all the registered shards and the corresponding chronicle nodes that should participate in those shards.  At the start of each session, the Timechain determines the number of shards to be created and assigns a shard ID to each shard (i.e., a unique identifier). It then randomly assigns the registered chronicle nodes to their respective shards.',
  },
];

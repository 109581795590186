import { useLayoutEffect, useMemo, useState } from 'react';

  const INITIAL_VALUE = {
    width: 0,
    height: 0,
  };

  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(INITIAL_VALUE);

    useLayoutEffect(() => {
      function handleResize() {
        const doc = document.getElementById('root');

        doc?.style.setProperty('--vh', `${window.innerHeight}px`);
        doc?.style.setProperty('--vw', `${window.innerWidth}px`);

        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener('resize', handleResize);

      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isMobile = useMemo(() => windowSize.width <= 520, [windowSize]);
    return { ...windowSize, isMobile };
  };

export default useWindowSize;

import { classes } from 'utils';

type IconButtonProps = {
  classNames?: string;
  icon: string;
  disabled?: boolean;
  iconClasses?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const IconButton = ({ classNames, iconClasses, icon, disabled, onClick }: IconButtonProps) => {
  return (
    <button
      className={classes(
        'h-9 w-9 md:h-10 md:w-10 bg-[#ffffff14] flex items-center justify-center rounded-full backdrop-blur-md',
        disabled && 'opacity-40',
        classNames
      )}
      disabled={disabled}
      onClick={onClick}
    >
      <img src={icon} className={classes('w-4 h-4', iconClasses)} alt="icon" />
    </button>
  );
};

export default IconButton;

import { DetailsList } from '@analog/ui';
import { Heading, SearchBox } from 'components';

import useValidatorDetails from './useValidatorDetails';

const ValidatorDetails = () => {
  const { isLoading, validator, validatorData } = useValidatorDetails();
  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        <Heading
          isLoading={isLoading}
          title={validator?.validatorDetails?.name as string}
          subTitle={validator?.validatorDetails?.address}
        />
        <SearchBox defaultSearchType={'Validators'} />
      </div>
      <div className="flex flex-col mt-8 md:mt-[60px] gap-8 lg:gap-10">
        <DetailsList title={'Validator Info'} details={validatorData} isLoading={isLoading} />
      </div>
    </>
  );
};

export default ValidatorDetails;

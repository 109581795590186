export const DisclosurePersonalData = () => {
  return (
    <div>
      <h2 className="text-white text-2xl	md:text-[32px] md:leading-[40px]">
        Disclosure of Your Personal Data
      </h2>
      <div className="flex flex-col gap-10 mt-4">
        <div>
          <h4 className="text-xl md:text-2xl text-white mb-2 md:mb-[10px]">
            Business Transactions
          </h4>
          <p>
            If the Company is involved in a merger, acquisition or asset sale, Your Personal Data
            may be transferred. We will provide notice before Your Personal Data is transferred and
            becomes subject to a different Privacy Policy.
          </p>
        </div>
        <div>
          <h4 className="text-xl md:text-2xl text-white mb-2 md:mb-[10px]">Law enforcement</h4>
          <p>
            Under certain circumstances, the Company may be required to disclose Your Personal Data
            if required to do so by law or in response to valid requests by public authorities (e.g.
            a court or a government agency).
          </p>
        </div>
        <div>
          <h4 className="text-xl md:text-2xl text-white mb-2 md:mb-[10px]">
            Other legal requirements
          </h4>
          <p className="mb-[10px]">
            The Company may disclose Your Personal Data in the good faith belief that such action is
            necessary to:
          </p>
          <ul className="list-disc flex flex-col gap-2 pl-6 md:pl-8">
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
          </ul>
        </div>
        <div>
          <h4 className="text-xl md:text-2xl text-white mb-2 md:mb-[10px]">
            Security of Your Personal Data
          </h4>
          <p>
            The security of Your Personal Data is important to Us, but remember that no method of
            transmission over the Internet, or method of electronic storage is 100% secure. While We
            strive to use commercially acceptable means to protect Your Personal Data, We cannot
            guarantee its absolute security.
          </p>
        </div>
        <div>
          <h4 className="text-xl md:text-2xl text-white mb-2 md:mb-[10px]">
            Detailed Information on the Processing of Your Personal Data
          </h4>
          <p>
            The Service Providers We use may have access to Your Personal Data. These third-party
            vendors collect, store, use, process and transfer information about Your activity on Our
            Service in accordance with their Privacy Policies.
          </p>
        </div>

        {/* Analytics Starts */}
        <div>
          <h4 className="text-xl md:text-2xl text-white mb-2 md:mb-[10px]">Analytics</h4>
          <p className="mb-[6px] md:mb-[10px]">
            We may use third-party Service providers to monitor and analyze the use of our Service.
          </p>
          <div className="flex flex-col gap-[6px] md:gap-2 mt-[6px] md:mt-[10px]">
            <ul className="list-disc ml-4 text-white">
              <li>Google Analytics</li>
            </ul>
            <p>
              Google Analytics is a web analytics service offered by Google that tracks and reports
              website traffic. Google uses the data collected to track and monitor the use of our
              Service. This data is shared with other Google services. Google may use the collected
              data to contextualize and personalize the ads of its own advertising network.
            </p>
            <p>
              You can opt-out of having made your activity on the Service available to Google
              Analytics by installing the Google Analytics opt-out browser add-on. The add-on
              prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing
              information with Google Analytics about visits activity. For more information on the
              privacy practices of Google, please visit the Google Privacy & Terms web page:
              <a href="https://policies.google.com/privacy" className="text-link cursor-pointer">
                {' '}
                https://policies.google.com/privacy
              </a>
            </p>
          </div>

          <div className="flex flex-col gap-[6px] md:gap-2 mt-6 md:mt-10">
            <ul className="list-disc ml-4 text-white">
              <li>Email Marketing</li>
            </ul>
            <p>
              We may use Your Personal Data to contact You with newsletters, marketing or
              promotional materials and other information that may be of interest to You. You may
              opt-out of receiving any, or all, of these communications from Us by following the
              unsubscribe link or instructions provided in any email We send or by contacting Us.
            </p>
            <p>We may use Email Marketing Service Providers to manage and send emails to You.</p>
          </div>

          <div className="flex flex-col gap-[6px] md:gap-2 mt-6 md:mt-10">
            <ul className="list-disc ml-4 text-white">
              <li>MailerLite</li>
            </ul>
            <p>
              Their Privacy Policy can be viewed at{' '}
              <a
                href="https://www.mailerlite.com/legal/privacy-policy"
                className="text-link cursor-pointer"
              >
                {' '}
                https://www.mailerlite.com/legal/privacy-policy
              </a>
            </p>
          </div>
        </div>
        {/* Analytics Ends */}

        <div>
          <h4 className="text-xl md:text-2xl text-white mb-[2px] md:mb-[10px]">
            Behavioral Remarketing
          </h4>
          <div className="flex flex-col gap-[6px] md:gap-4">
            <p>
              The Company uses remarketing services to advertise to You after You accessed or
              visited our Service. We and Our third-party vendors use cookies and non-cookie
              technologies to help Us recognize Your Device and understand how You use our Service
              so that We can improve our Service to reflect Your interests and serve You
              advertisements that are likely to be of more interest to You.
            </p>
            <p>
              These third-party vendors collect, store, use, process and transfer information about
              Your activity on Our Service in accordance with their Privacy Policies and to enable
              Us to:
            </p>
            <div className="flex flex-col  gap-[6px] md:gap-2">
              <ul className="list-disc ml-4">
                <li>Measure and analyze traffic and browsing activity on Our Service</li>
                <li>
                  Show advertisements for our products and/or services to You on third-party
                  websites or apps
                </li>
                <li>Measure and analyze the performance of Our advertising campaigns</li>
              </ul>
            </div>
            <p>
              Some of these third-party vendors may use non-cookie technologies that may not be
              impacted by browser settings that block cookies. Your browser may not permit You to
              block such technologies. You can use the following third-party tools to decline the
              collection and use of information for the purpose of serving You interest-based
              advertising:
            </p>
            <div className="flex flex-col  gap-[6px] md:gap-2">
              <ul className="list-disc ml-4">
                <li>
                  The NAI's opt-out platform:{' '}
                  <a
                    href="http://www.networkadvertising.org/choices/"
                    className="text-link cursor-pointer"
                  >
                    http://www.networkadvertising.org/choices/
                  </a>
                </li>
                <li>
                  The EDAA's opt-out platform:{' '}
                  <a href="http://www.youronlinechoices.com/" className="text-link cursor-pointer">
                    http://www.youronlinechoices.com/
                  </a>
                </li>
                <li>
                  The DAA's opt-out platform:{' '}
                  <a
                    href="http://optout.aboutads.info/?c=2&lang=EN"
                    className="text-link cursor-pointer"
                  >
                    http://optout.aboutads.info/?c=2&lang=EN
                  </a>
                </li>
              </ul>
            </div>
            <p>
              You may opt-out of all personalized advertising by enabling privacy features on Your
              mobile device such as Limit Ad Tracking (iOS) and Opt Out of Ads Personalization
              (Android). See Your mobile device Help system for more information.
            </p>
            <p>
              We may share information, such as hashed email addresses (if available) or other
              online identifiers collected on Our Service with these third-party vendors. This
              allows Our third-party vendors to recognize and deliver You ads across devices and
              browsers. To read more about the technologies used by these third-party vendors and
              their cross-device capabilities please refer to the Privacy Policy of each vendor
              listed below.
            </p>
            <p>The third-party vendors We use are:</p>
            <ul className="list-disc ml-4 text-white">
              <li>Twitter</li>
            </ul>
            <p>
              Twitter remarketing service is provided by Twitter Inc. You can opt-out from Twitter's
              interest-based ads by following their instructions:{' '}
              <a
                href="https://support.twitter.com/articles/20170405"
                className="text-link cursor-pointer"
              >
                https://support.twitter.com/articles/20170405{' '}
              </a>
            </p>
            <p>
              You can learn more about the privacy practices and policies of Twitter by visiting
              their Privacy Policy page:{' '}
              <a href="https://twitter.com/privacy" className="text-link cursor-pointer">
                https://twitter.com/privacy
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

import { createContext, useContext } from 'react';

import { useHealthCheckQuery } from 'gql';

type Value = {
  sessionKey?: string;
};

export const ApiAccessContext = createContext<Value>({});

export function AccessProvider({ children }: React.PropsWithChildren) {
  const sessionKey = '5UPu3ApZshkoge4JhYDZM2LkZbwGMWb4qbX3m9LrRv1Z';

  const { loading, error } = useHealthCheckQuery({
    onError() {
      window.location.assign('/maintenance.html');
    },
    variables: {
      sessionKey,
    },
  });

  if (loading || error) {
    return <></>;
  }

  const value = {
    sessionKey,
  };

  return <ApiAccessContext.Provider value={value}>{children}</ApiAccessContext.Provider>;
}

export const useApiAccess = () => useContext(ApiAccessContext);

import { HashIcon, Icon } from '@analog/ui';
import allImgPaths from 'assets/allImgPaths';
import { AccountDetailsQuery } from 'gql';
import { formatAmount } from 'utils';

interface AccountDataProps {
  data: AccountDetailsQuery | undefined;
}

const AccountData = ({ data }: AccountDataProps) => {
  return [
    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Address',
      value: <HashIcon value={data?.accountDetails?.address} className="text-white" copyable />,
    },
    {
      icon: <Icon icon={allImgPaths.dollarIcon} />,
      label: 'Reserved',
      value: formatAmount(data?.accountDetails.data.data.reserved),
    },
    {
      icon: <Icon icon={allImgPaths.dollarIcon} />,
      label: 'Frozen',
      value: formatAmount(data?.accountDetails.data.data.frozen),
    },
    {
      icon: <Icon icon={allImgPaths.dollarIcon} />,
      label: 'Free',
      value: formatAmount(data?.accountDetails?.data?.data?.free),
    },
    {
      icon: <Icon icon={allImgPaths.flagsIcon} />,
      label: 'Flags',
      value: data?.accountDetails.data.data.flags,
    },
    {
      icon: <Icon icon={allImgPaths.shardNetworkIcon} />,
      label: 'Consumers',
      value: data?.accountDetails.data.consumers,
    },
    {
      icon: <Icon icon={allImgPaths.clipboardCloseIcon} />,
      label: 'Nonce',
      value: data?.accountDetails.data.nonce,
    },
    {
      icon: <Icon icon={allImgPaths.shardNetworkIcon} />,
      label: 'Providers',
      value: data?.accountDetails.data.providers,
    },
    {
      icon: <Icon icon={allImgPaths.cube3DIcon} />,
      label: 'sufficients',
      value: data?.accountDetails.data.sufficients,
    },
  ];
};
export default AccountData;

import { ReactNode } from 'react';

import { classes } from 'utils';

const CardClasses =
  'rounded-3xl w-full p-4 lg:px-6 lg:py-5 border bg-[#060606] border-solid border-[rgba(255,255,255,0.1)] [box-shadow:0px_-2px_52px_0px_rgba(200,_200,_200,_0.06)_inset,_0px_8px_10px_0px_#000] relative group';

const CardHoverClasses = ' hover:bg-[rgba(255,255,255,0.12)] ';

interface CardProps {
  children: ReactNode;
  extraClasses?: string;
  hoverEffect?: boolean;
}

const Card = ({ children, extraClasses = '', hoverEffect = false }: CardProps) => {
  return (
    <div className={classes(CardClasses, extraClasses, hoverEffect ? CardHoverClasses : '')}>
      {children}
    </div>
  );
};

export default Card;

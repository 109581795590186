import {
  Content,
  CopyrightPolicy,
  InterpretationDefinition,
  SeverabilityAndWaiver,
} from './components';

export const TermsAndConditions = () => {
  return (
    <div className="max-w-[800px] mx-auto text-offWhite text-base md:text-xl ">
      <div className="flex flex-col gap-3">
        <h1 className="text-white text-[32px] leading-[40px] md:text-5xl md:leading-[56px] font-medium">
          Terms and Conditions
        </h1>
        <p className="text-offWhite text-base md:text-[18px]">Version 1.0</p>
        <p className="text-offWhite text-base md:text-[18px]">Effective Date: July 19, 2024</p>
      </div>
      <div className="flex flex-col gap-4 mt-[32px] my-8 md:mt-[40px] md:mb-10">
        <h3 className="text-lg md:text-2xl text-white">Introduction </h3>
        <p>
          Welcome to Explorer, a platform for monitoring and managing blockchain transactions and
          data. These terms and conditions (“Terms”) govern your access to and use of the services
          provided by Analog Time Corporation Limited (“Analog”, “Company”, “we”, “us”, or “our”), a
          Seychelles International Business Corporation. Please read these terms and conditions
          carefully before using our Service.
        </p>
      </div>
      <div className="flex flex-col gap-10">
        <InterpretationDefinition />
        <Content />
        <CopyrightPolicy />
        <SeverabilityAndWaiver />
      </div>
    </div>
  );
};

export default TermsAndConditions;

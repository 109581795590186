import { DetailsList } from '@analog/ui';
import { Card, SearchBox, Table } from 'components';
import Heading from 'components/common/Heading';
import { Skeleton } from 'components/Skeleton';
import { arrayOfSize, classes, SortColumnsTask } from 'utils';

import { ShardDetailsCard } from './components/ShardDetailsCard';
import useShardDetails from './useShardDetails';
import { useTaskData } from './useTaskData';

function ShardDetails() {
  const { isLoading, shardData, shardMembers, id } = useShardDetails();

  const { tasksLoading, sortOrder, onSort, taskColumns, tasksData, pageSize, PageController } =
    useTaskData();

  return (
    <Skeleton.Provider isLoading={isLoading}>
      <div className="flex flex-col gap-4 md:gap-8">
        <Heading
          className="text-white  font-medium leading-none"
          title={`ShardID #${id}`}
          classNames={{ base: 'flex md:gap-[31px]' }}
          isLoading={isLoading}
        />
        <SearchBox defaultSearchType={'Shards'} />
      </div>
      <div className="md:mt-10 mt-8">
        <DetailsList title="Shard Info" details={shardData} isLoading={isLoading} />
      </div>

      {shardMembers.length && <ShardDetailsCard title="Shard Chronicle" data={shardMembers} />}

      <div className="mt-8 md:mt-10">
        <Card extraClasses="flex gap-4 md:gap-[22px] flex-col">
          <div className="flex items-end gap-2">
            <span className="text-white md:text-xl text-lg font-normal leading-6 capitalize">
              Tasks
            </span>
            <Skeleton.Loader className="w-16 h-5" containerClassName="flex">
              <span className="text-silver text-xs font-normal leading-5">
                {tasksData?.listTasks?.count || '0'} results
              </span>
            </Skeleton.Loader>
          </div>
          <Table
            columns={taskColumns}
            hasHeader
            data={!tasksLoading ? tasksData?.listTasks?.data : arrayOfSize(pageSize)}
            classNames={{
              row: classes(
                'last:border-none md:[&_>:nth-last-child(3)]:col-span-1 [&_>:nth-last-child(3)]:col-span-2 grid gap-x-4 grid-cols-2 md:grid-cols-[minmax(130px,10%)_minmax(140px,10%)_minmax(120px,10%)_minmax(150px,15%)_minmax(140px,20%)_minmax(120px,20%)_minmax(100px,10%)]'
              ),
              bodyRow: '!py-4 items-start !h-auto',
              columns: 'justify-start',
              bodyWrapper: 'min-h-[195px] border-b border-lightGray',
            }}
            sortOptions={SortColumnsTask}
            sortOrder={sortOrder}
            onSort={onSort}
            isLoading={tasksLoading}
            alwaysShowHeader
          />
          <Skeleton.Provider isLoading={tasksLoading}>
            {(tasksLoading || tasksData?.listTasks?.data?.length) && PageController}
          </Skeleton.Provider>
        </Card>
      </div>
    </Skeleton.Provider>
  );
}

export default ShardDetails;


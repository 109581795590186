import { useState } from 'react';

import allImgPaths from 'assets/allImgPaths';
import { classes } from 'utils';

export type Item = {
  title?: string;
  content: string;
};

export interface AccordionProps {
  items: Array<Item>;
}

const Accordion = ({ items }: AccordionProps) => {
  const [isExpandedIndex, setIsExpandedIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setIsExpandedIndex((prev) => {
      if (prev !== null && prev === index) {
        return null;
      } else return index;
    });
  };

  return (
    <div className="relative antialiased">
      <div className="divide-y divide-x-0 divide-solid divide-lightGray">
        {items.map((item: Item, index: number) => {
          return (
            <div className="py-4 md:py-[22px]" key={index}>
              <h2>
                <button
                  type="button"
                  className="flex items-center justify-between w-full text-left"
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="text-white font-normal">{item.title}</span>
                  <img
                    src={isExpandedIndex === index ? allImgPaths.subtractIcon : allImgPaths.addIcon}
                    alt="toggle"
                    className={classes(
                      'w-6 h-6 transform origin-center transition-all duration-200 ease-out',
                      isExpandedIndex === index ? 'rotate-0' : 'rotate-90'
                    )}
                  />
                </button>
              </h2>
              <div
                className={classes(
                  'grid text-sm text-white overflow-hidden transition-all duration-200 ease-linear',
                  isExpandedIndex === index
                    ? 'grid-rows-[1fr] opacity-100'
                    : 'grid-rows-[0fr] opacity-0'
                )}
              >
                <div className="overflow-hidden">
                  <div className="pt-4 md:pt-3 text-offWhite">
                    {item.content.split('\n').map((el: string) => (
                      <p>{el}</p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Accordion;

import { useEffect } from 'react';

import { useApiAccess } from 'contexts/AccessProvider';
import { useExtrinsicDetailsQuery } from 'gql';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'routes/routesConst';

import OverviewDetails from './components/OverviewDetails';

const useExtrinsicDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { sessionKey } = useApiAccess();

  const {
    data: extrinsicDetailsData,
    loading: isLoading,
    error,
  } = useExtrinsicDetailsQuery({
    variables: { sessionKey, extrinsicId: id || '' },
  });

  useEffect(() => {
    if (error?.message.includes('not exist')) {
      navigate(routes[404]);
    }
  }, [error, navigate]);

  const overviewDetails = OverviewDetails({ extrinsicDetailsData });

  return {
    isLoading,
    id,
    overviewDetails,
  };
};

export default useExtrinsicDetails;

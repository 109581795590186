import { DEFINITIONS } from './utils';

export const Interpretation = () => {
  return (
    <div>
      <h2 className="text-white text-2xl	md:text-[32px] md:leading-[40px]">
        Interpretation and Definitions
      </h2>
      <div className="flex flex-col mt-3 md:mt-4">
        <div>
          <h3 className="text-white text-[20px] md:text-6 leading-[28px]">Interpretation</h3>
          <p className="mt-2">
            The words of which the initial letter is capitalized have meanings defined under the
            following conditions. The following definitions shall have the same meaning regardless
            of whether they appear in singular or in plural.
          </p>
        </div>
        <h3 className="text-white text-[20px] leading-[28px] md:text-6 mt-8 md:mt-10 mb-2 md:mb-[10px]">
          Definitions
        </h3>
        <p className="mb-4">For the purposes of this Privacy Policy:</p>
        <div className="flex flex-col gap-8">
          {DEFINITIONS.map(({ title, description }) => {
            return (
              <p>
                <span className="text-white">{title}</span> {description}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

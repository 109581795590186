import { ReactNode } from 'react';

import { Skeleton } from 'components/Skeleton';

type ColumnWrapperProps = {
  children: ReactNode;
  title: string;
  isHeaderHidden: boolean;
};

const ColumnWrapper = ({ children, title, isHeaderHidden }: ColumnWrapperProps) => {
  return (
    <div className="flex flex-col gap-2 overflow-hidden w-[95%]">
      {!isHeaderHidden && (
        <Skeleton.Loader className="w-full h-full" containerClassName="md:hidden w-[75px] h-5">
          <span className="md:hidden leading-5 capitalize text-offWhite text-xs w-full">
            {title}
          </span>
        </Skeleton.Loader>
      )}
      {children}
    </div>
  );
};

export default ColumnWrapper;

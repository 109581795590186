import allImgPaths from 'assets/allImgPaths';
import { Card, Table } from 'components';
import { membersColumn } from 'pages/Chronicles/ChronicleList/ChronicleList';
import { routes } from 'routes/routesConst';
import { SortColumnsMembers, arrayOfSize } from 'utils';

import useMembersTable from './useMembersTable';

export const MembersTable = () => {
  const { membersLoading, membersData, membersSortOrder, membersOnSort, navigate } =
    useMembersTable();

  return (
    <Card extraClasses="rounded-[20px] !p-5 flex flex-col gap-[18px]">
      <div className="flex justify-between">
        <span className="text-white text-xl">Chronicles</span>
      </div>
      <Table
        columns={membersColumn}
        hasHeader
        data={!membersLoading ? membersData?.listMembers.data : arrayOfSize(6)}
        classNames={{
          row: 'grid gap-x-2 grid-cols-2 md:grid-cols-[minmax(140px,35%)_minmax(110px,15%)_minmax(130px,30%)_minmax(100px,10%)_minmax(100px,7%)_minmax(100px,5%)]',
          base: 'md:max-h-[530px] overflow-auto',
        }}
        sortOptions={SortColumnsMembers}
        sortOrder={membersSortOrder}
        onSort={membersOnSort}
        isLoading={membersLoading}
      />
      {membersData?.listMembers?.data?.length && (
        <div className="flex justify-center">
          <button
            className="flex flex-row gap-[5px] items-center"
            onClick={() => navigate(routes.chronicles)}
          >
            <span className="text-white whitespace-nowrap cursor-pointer">View All</span>
            <img
              src={allImgPaths.rightArrowIcon}
              alt="right-arrow"
              className="h-[14px] w-[14px] md:h-4 md:w-4 mt-[2.85px]"
            />
          </button>
        </div>
      )}
    </Card>
  );
};

import { Hashicon, HashiconProps } from '@emeraldpay/hashicon-react';
import { HTMLAttributes } from 'types';
import { classes } from 'utils';

import { Skeleton } from '../Skeleton';

interface IdenticonProps extends HTMLAttributes<HTMLDivElement> {
  size?: HashiconProps['size'];
  value?: string;
}

export function Identicon({ className, size, value, ...props }: IdenticonProps) {
  return (
    <div className={classes('shrink-0 ', className)} {...props}>
      <Skeleton.Loader className={className} containerClassName="h-full pt-4" isLoading={!value}>
        {value && <Hashicon size={size || 24} value={value} />}
      </Skeleton.Loader>
    </div>
  );
}

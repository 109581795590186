import { Shards } from 'gql';

export type Page =
  | 'Landing'
  | 'Blocks'
  | 'BlockDetails'
  | 'Events'
  | 'Extrinsics'
  | 'ExtrinsicsDetails'
  | 'GMP'
  | 'TaskDetails'
  | 'Validators'
  | 'ValidatorDetails'
  | 'Shards'
  | 'ShardDetails'
  | 'Chronicles'
  | 'ChroniclesDetails'
  | 'FAQ'
  | 'NotFound';

export type HTMLAttributes<T> = React.HTMLAttributes<T> & {
  className?: string;
};

export type TaskType = {
  __typename?: 'tasks' | undefined;
  id: number;
  type?: string | null | undefined;
  shardId?: string | null | undefined;
  definition?: any;
  created_at?: Date;
  destination?: string | null;
  modified_at_block?: number;
  network?: string | null;
  payload?: any;
  phase?: string | null;
  proposed_at_block?: number;
  shard?: Shards;
  sig?: string | null;
  source?: string | null;
  sourceNetwork?: string | null;
  unAssignedReason?: string | null;
  updated_at?: Date;
};

export const enum GMPTaskType {
  ReadMessages = 'ReadMessages',
  SendMessage = 'SendMessage',
}

import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';

import { socialHandlers } from './consts';

const options = [
  {
    title: 'FAQ',
    to: routes.faqs,
  },
  { title: 'Privacy Policy', to: routes.privacyPolicy },
  { title: 'Terms of Use', to: routes.termsAndConditions },
];

const NewFooter = () => {
  return (
    <div className="relative z-[1] pb-4 flex flex-col font-['Aeonik']">
      <div className="md:flex block justify-between items-center md:pt-0 pt-[26px]">
        <span className="text-[#fffc]">© {new Date().getFullYear()} Analog</span>
        <div className="md:flex flex-row block gap-4 items-center">
          <div className="text-silver gap-4 flex md:my-0 my-3">
            {options.map((option, index) => (
              <Link
                key={index}
                className="text-[#B2B3B8] cursor-pointer"
                to={option.to}
                replace={true}
              >
                {option.title}
              </Link>
            ))}
          </div>
          <ul className="flex gap-2">
            {socialHandlers.map(({ img, link }, index) => (
              <Link key={index} to={link} target="_blank">
                <div className="flex justify-center items-center cursor-pointer rounded-[7px] bg-white/10 w-[32px] h-[32px] backdrop-blur-[11px]">
                  <img src={img} className="h-4 w-4" />
                </div>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NewFooter;

export const TRACKING_TECH = [
  {
    title: 'Necessary / Essential Cookies',
    type: 'Session Cookies',
    administeredBy: 'Us',
    purpose:
      'These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.',
  },
  {
    title: 'Cookies Policy / Notice Acceptance Cookies',
    type: 'Persistent Cookies',
    administeredBy: 'Us',
    purpose: 'These Cookies identify if users have accepted the use of cookies on the Website.',
  },
  {
    title: 'Functionality Cookies',
    type: 'Persistent Cookies',
    administeredBy: 'Us',
    purpose:
      'These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.',
  },
  {
    title: 'Tracking and Performance Cookies',
    type: 'Persistent Cookies',
    administeredBy: 'Third-Parties',
    purpose:
      'These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new pages, features or new functionality of the Website to see how our users react to them.',
  },
];

export const PERSONALDATA_PURPOSES_ONE = [
  {
    title: 'To provide and maintain our Service',
    desc: 'including to monitor the usage of our Service.',
  },
  {
    title: 'To manage Your Account',
    desc: 'to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.',
  },
  {
    title: 'For the performance of a contract:',
    desc: 'the development, compliance, and undertaking of the purchase contract for the products, items, or services You have purchased or of any other contract with Us through the Service.',
  },
  {
    title: 'To contact You:',
    desc: "To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including security updates when necessary or reasonable for their implementation.",
  },
  {
    title: 'To provide You:',
    desc: 'with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.',
  },
  {
    title: 'To manage Your requests:',
    desc: 'To attend and manage Your requests to Us.',
  },
  {
    title: 'To deliver targeted advertising to You:',
    desc: 'We may use Your information to develop and display content and advertising (and work with third-party vendors who do so) tailored to Your interests and/or location and to measure its effectiveness.',
  },
  {
    title: 'For business transfers:',
    desc: 'We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.',
  },
  {
    title: 'For other purposes:',
    desc: 'We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and evaluating and improving our Service, products, services, marketing, and your experience.',
  },
];

export const PERSONALDATA_PURPOSES_TWO = [
  {
    title: 'With Service Providers:',
    desc: 'We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to advertise on third-party websites to You after You visited our Service, and to contact You.',
  },
  {
    title: 'For business transfers:',
    desc: 'We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.',
  },
  {
    title: 'With Affiliates:',
    desc: 'We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners, or other companies that We control or that are under common control with Us.',
  },
  {
    title: 'With business partners:',
    desc: 'We may share Your information with Our business partners to offer You certain products, services, or promotions.',
  },
  {
    title: 'With other users:',
    desc: 'When You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures, and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You, and view Your profile.',
  },
  {
    title: 'With Your consent:',
    desc: 'We may disclose Your personal information for any other purpose with Your consent.',
  },
];

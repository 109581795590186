import { useEffect } from 'react';

import allImgPaths from 'assets/allImgPaths';
import { useApiAccess } from 'contexts/AccessProvider';
import { useShardDetailsQuery } from 'gql';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'routes/routesConst';

import ShardData from './components/ShardData';
const useShardDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { sessionKey } = useApiAccess();
  const {
    data,
    loading: isLoading,
    error,
  } = useShardDetailsQuery({
    variables: {
      shardId: id as string,
      sessionKey,
    },
  });

  useEffect(() => {
    if (error?.message.includes('not exist')) {
      navigate(routes[404]);
    }
  }, [error]);

  const shardData = ShardData({ data });

  const shardMembers =
    data?.shardDetails?.members.map((member) => ({
      image: allImgPaths.profileIcon,
      title: 'Chronicle',
      text: member.account_id,
    })) || [];
  return { isLoading, shardMembers, shardData, id, data };
};
export default useShardDetails;

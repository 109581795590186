import { forwardRef } from 'react';

import { HTMLAttributes } from 'types';
import { classNames, classes } from 'utils';

type Variant = 'darkThemeFilled' | 'darkThemeOutlined';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  isDisabled?: boolean;
  isIcon?: boolean;
  isLoading?: boolean;
  isNegative?: boolean;
  isSubmit?: boolean;
  ref?: React.MutableRefObject<HTMLButtonElement | null>;
  variant?: Variant;
  isDarkTheme?: boolean;
  classNames?: classNames<'container'>;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = 'darkThemeOutlined',
      className,
      isDisabled,
      isIcon,
      isLoading,
      classNames,
      ...rest
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={'button'}
        className={classes(
          'btn relative h-10 max-h-full',
          isIcon && 'icon',
          variant,
          className,
          variant === 'darkThemeOutlined' && '',
          classNames?.base
        )}
        {...(isDisabled || isLoading ? { disabled: true } : {})}
        {...rest}
      >
        {isLoading && (
          <div
            style={{
              borderTopColor: 'transparent',
              left: 'calc(50% - 7px)',
            }}
            className="absolute h-3.5 w-3.5 animate-spin rounded-full border border-solid border-white"
          />
        )}
        <div
          className={classes(
            'flex items-center justify-center whitespace-pre text-sm',
            isLoading ? 'invisible' : '',
            classNames?.container
          )}
        >
          {children}
        </div>
      </button>
    );
  }
);

Button.displayName = 'Button';

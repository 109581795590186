import { AccessProvider } from 'contexts/AccessProvider';
import { SkeletonTheme } from 'react-loading-skeleton';
import { RouterProvider } from 'react-router-dom';

import { router } from './routes/routes';

function App() {
  return (
    <SkeletonTheme borderRadius={0} baseColor="#FFFFFF1F">
      <AccessProvider>
        <RouterProvider router={router} />
      </AccessProvider>
    </SkeletonTheme>
  );
}

export default App;

import allImgPaths from '../assets/allImgPaths';

// import allImgPaths from 'assets/allImgPaths';
enum ICONS {
  Polygon = 'polygon',
  Binance = 'binance',
  Ethereum = 'ethereum',
  Bitcoin = 'bitcoin',
  Optimism = 'optimism',
  Online = 'online',
  Offline = 'offline',
  Active = 'active',
  Astar = 'astar',
}

export enum SearchType {
  Block = 'block',
  Events = 'events',
  Extrinsics = 'extrinsics',
  Members = 'members',
  Shards = 'shards',
  Tasks = 'tasks',
}

export const getIcon = (name: string) => {
  name = name?.toLowerCase();
  switch (name) {
    case ICONS.Polygon:
      return allImgPaths.polygonIcon;
    case ICONS.Binance:
      return allImgPaths.binanceIcon;
    case ICONS.Bitcoin:
      return allImgPaths.bitcoinIcon;
    case ICONS.Ethereum:
      return allImgPaths.ethereumIcon;
    case ICONS.Optimism:
      return allImgPaths.optimismIcon;
    case ICONS.Offline:
      return allImgPaths.offlineIcon;
    case ICONS.Online:
      return allImgPaths.onlineIcon;
    case ICONS.Active:
      return allImgPaths.online2Icon;
    case ICONS.Astar:
      return allImgPaths.astarIcon;
    case SearchType.Block:
      return allImgPaths.layerIcon;
    case SearchType.Events:
      return allImgPaths.calendarTickIcon;
    case SearchType.Extrinsics:
      return allImgPaths.dataArrowsIcon;
    case SearchType.Members:
      return allImgPaths.profileIcon;
    case SearchType.Shards:
      return allImgPaths.hashTagOutlineIcon;
    case SearchType.Tasks:
      return allImgPaths.taskIcon;
    case 'validators':
      return allImgPaths.verifyIcon;

    default:
      break;
  }
};

import allImgPaths from 'assets/allImgPaths';
import { Identicon } from 'components';
import { Skeleton } from 'components/Skeleton';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';
import { getIcon, parseJson } from 'utils';

const ChronicleColumns = () => {
  return [
    {
      title: 'Account ID',
      key: 'account_id',
      shouldWrap: false,
      render: (data: ColumnData) => (
        <Link
          className="flex gap-[10px] col-span-2 md:col-span-1 cursor-pointer items-center"
          to={`${routes.chronicle}/${data[data.columnKey]}`}
          state={{ data: data }}
        >
          <Skeleton.Loader className="h-7 w-7 !rounded-md" containerClassName="flex">
            <Identicon value={data[data.columnKey]} size={24} />
          </Skeleton.Loader>
          <div className={'flex flex-col overflow-hidden w-full'}>
            <TableColumns
              value={data[data.columnKey]}
              withTruncate
              withCopyButton
              textColor={FontColors.PURPLE}
              label="User"
            />
          </div>
        </Link>
      ),
    },
    {
      title: 'Network',
      key: 'network',
      render: (data: ColumnData) => {
        const parsedData = parseJson(data[data.columnKey]);

        return (
          <div className="flex gap-1 flex-wrap">
            {parsedData.length ? (
              parsedData?.map((value: string, index: number) => (
                <TableColumns
                  key={index}
                  beforeImagePath={value && getIcon(value)}
                  value={value + (index < parsedData.length - 1 ? ',' : '')}
                  textColor={FontColors.WHITE}
                  classNames={{ container: '!w-fit', base: '!w-fit', content: 'capitalize' }}
                />
              ))
            ) : (
              <TableColumns value="-" />
            )}
          </div>
        );
      },
    },
    {
      title: 'Signature',
      key: 'sig',
      tooltipContent:
        'A cryptographic output that each active Chronicle Node in a shard generates whenever it is called upon to sign off on a cross-chain transaction.',
      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey]}
          withTruncate
          withCopyButton
          colIndex={data.colIndex}
          dataTooltipContent="Copy Address"
          dataTooltipId="signature"
          classNames={{
            content: ' text-white',
          }}
          copyValue={data[data.columnKey]}
        />
      ),
    },
    {
      title: 'State',
      key: 'state',
      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey]}
          beforeImagePath={data[data.columnKey] ? getIcon(data[data.columnKey]) : '-'}
          classNames={{
            beforeIcon: 'h-[9px] w-[9px]',
            content: 'capitalize text-white',
            skeletonText: 'min-w-5 max-w-10',
          }}
        />
      ),
    },
    {
      title: 'Shard ID',
      key: 'shardId',
      render: (data: ColumnData) =>
        data[data.columnKey] ? (
          <Link className="cursor-pointer w-fit" to={`${routes.shard}/${data[data.columnKey]}`}>
            <TableColumns
              value={data[data.columnKey]}
              textColor={FontColors.PURPLE}
              classNames={{ container: '!w-fit', base: '!w-fit' }}
            />
          </Link>
        ) : (
          <TableColumns value="-" />
        ),
    },
    {
      title: 'Heartbeat',
      key: 'heartbeat',
      tooltipContent:
        'A special transaction that validators //(i.e., Chronicle Nodes and Time Nodes) broadcast to the Timechain, proving that they are connected and active.',
      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey] || '-'}
          beforeImagePath={data[data.columnKey] ? allImgPaths.heartIcon : '-'}
          classNames={{
            base: 'md:justify-end',
            beforeIcon: 'h-[10px] min-w-3 w-3',
            content: 'md:ml-auto capitalize text-white',
            container: '!w-fit',
          }}
        />
      ),
    },
  ];
};
export default ChronicleColumns;

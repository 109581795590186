import React, { useState } from 'react';

import { Card } from 'components';
import { Skeleton } from 'components/Skeleton';
import { TelemetryQuery } from 'gql';
import { getIcon } from 'utils';

import useTelemetry, { NetworkStatsType } from './useTelemetry';

const NetworkStats: { title: string; key: keyof NetworkStatsType }[] = [
  { title: 'Finalized Blocks', key: 'finalized_blocks' },
  { title: 'Active Chronicles', key: 'active_members' },
  { title: 'Transfers', key: 'transfers' },
  { title: 'Shards Live', key: 'shards_live' },
  { title: 'Tasks Created', key: 'tasks_created' },
];
export const AnalogNetworkStats = (stats: NetworkStatsType) => {
  const [hoverPositions, setHoverPositions] = useState<{ x: number; y: number }[]>(
    Array.from({ length: NetworkStats.length }, () => ({ x: 0, y: 0 }))
  );
  const [isHovering, setIsHovering] = useState<boolean[]>(
    Array.from({ length: NetworkStats.length }, () => false)
  );

  const handleMouseMove = (index: number, e: React.MouseEvent<HTMLDivElement>) => {
    const containerRect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - containerRect.left;
    const y = e.clientY - containerRect.top;

    const newPositions = [...hoverPositions];
    newPositions[index] = { x, y };
    setHoverPositions(newPositions);
  };

  const handleMouseEnter = (index: number) => {
    const newHoverState = [...isHovering];
    newHoverState[index] = true;
    setIsHovering(newHoverState);
  };

  const handleMouseLeave = (index: number) => {
    const newHoverState = [...isHovering];
    newHoverState[index] = false;
    setIsHovering(newHoverState);
  };
  return (
    <div className="flex gap-4 flex-col">
      <span className="md:text-2xl text-xl text-white">Analog Network Stats</span>
      <div className="gap-4 lg:gap-6 justify-between grid grid-cols-2  xl:grid-cols-5 grid-flow-row [&>*:nth-child(5)]:col-span-2 xl:[&>*:nth-child(5)]:col-span-1">
        {NetworkStats.map(({ title, key }, index) => (
          <Card key={index} hoverEffect extraClasses="overflow-hidden h-max">
            <div
              className="flex flex-col h-full relative"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
              onMouseMove={(e) => handleMouseMove(index, e)}
            >
              <div
                className="absolute h-[70px] w-[70px] rounded-[38px] bg-[#ff00f566] bottom-7 right-5 blur-[25px] hidden group-hover:flex z-[-1]"
                style={{ top: hoverPositions[index].y - 25, left: hoverPositions[index].x - 25 }}
              ></div>
              <div>
                <p className="text-white text-sm lg:text-base whitespace-nowrap">{title}</p>
                <div className="flex gap-2 flex-col h-full">
                  <div className="flex items-end mt-[14px] gap-[7px] flex-wrap">
                    <Skeleton.Loader className="w-20 h-10" containerClassName="h-10">
                      <span className="text-[32px] lg:text-[40px] text-white font-medium leading-9 lg:leading-[56px]">
                        {new Number(stats[key]).toLocaleString()}
                      </span>
                    </Skeleton.Loader>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};
export const ChainsSupported = ({ chains }: { chains: string[] }) => {
  return (
    <div className="flex gap-4 flex-col">
      <Skeleton.Loader className="w-[10%] h-8">
        <span className="md:text-2xl text-xl text-white">Chains Supported</span>
      </Skeleton.Loader>
      <div className="gap-4 lg:gap-6 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
        {chains.map((chain, index) => (
          <Card extraClasses="!py-[14px] !pl-4 rounded-2xl" hoverEffect key={index}>
            <div className="flex gap-[9px]">
              <Skeleton.Loader className="w-10 h-10" containerClassName="h-10">
                <img src={getIcon(chain)} alt={chain} className="w-10 h-10" />
              </Skeleton.Loader>
              <div className="flex flex-col">
                <Skeleton.Loader className="w-[70px] h-3" containerClassName="h-3">
                  <span className="text-offWhite text-xs">Chain</span>
                </Skeleton.Loader>
                <Skeleton.Loader className="w-[100px] h-5 mt-2" containerClassName="h-5">
                  <span className="text-white capitalize">{chain}</span>
                </Skeleton.Loader>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

const Telemetry = ({
  telemetryData,
  isTelemetryLoading,
  supportedChain,
  isSupportedChainLoading,
}: {
  telemetryData: TelemetryQuery | undefined;
  isTelemetryLoading?: boolean;
  supportedChain?: string[];
  isSupportedChainLoading?: boolean;
}) => {
  const { networkStats, chains } = useTelemetry({ telemetryData, supportedChain });
  return (
    <>
      <Skeleton.Provider isLoading={isTelemetryLoading}>
        <AnalogNetworkStats {...networkStats} />
      </Skeleton.Provider>
      <Skeleton.Provider isLoading={isSupportedChainLoading}>
        <ChainsSupported
          chains={!isSupportedChainLoading ? (supportedChain?.length ? chains : []) : ['']}
        />
      </Skeleton.Provider>
    </>
  );
};

export default Telemetry;

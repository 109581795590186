import {
  CollectingUsingPersonalData,
  DisclosurePersonalData,
  FacebookFan,
  GDPRPrivacy,
  Interpretation,
} from './components';

export const PrivacyPolicy = () => {
  return (
    <div className="max-w-[800px] mx-auto text-offWhite text-base md:text-xl ">
      <div className="flex flex-col gap-3">
        <h1 className="text-white text-[32px] leading-[40px] md:text-5xl md:leading-[56px]">
          Analog Privacy Policy
        </h1>
        <p className="text-offWhite text-base md:text-[18px]">Version 1.0</p>
        <p className="text-offWhite text-base md:text-[18px]">Effective April 12, 2024</p>
      </div>
      <div className="flex flex-col gap-4 mt-[32px] my-8 md:mt-[50px] md:mb-10">
        <p>
          This Privacy Policy describes Our policies and procedures on the collection, use and
          disclosure of Your information when You use the Service and tells You about Your privacy
          rights and how the law protects You.
        </p>
        <p className="">
          We use Your Personal data to provide and improve the Service. By using the Service, You
          agree to the collection and use of information in accordance with this Privacy Policy.
        </p>
      </div>
      <div className="flex flex-col gap-10">
        <Interpretation />
        <CollectingUsingPersonalData />
        <DisclosurePersonalData />
        <GDPRPrivacy />
        <FacebookFan />
      </div>
    </div>
  );
};

export default PrivacyPolicy;

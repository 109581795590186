import { useEffect } from 'react';

import ExtrinsicsColumns from 'components/common/ExtrinsicsColumns';
import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { useAccountDetailsQuery, useGetExtrinsicsQuery } from 'gql';
import { useSortBy } from 'hooks';
import usePagination from 'hooks/usePagination';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'routes/routesConst';

import AccountData from './components/AccountData';

const useAccountDetails = () => {
  const { id } = useParams();
  const { sessionKey } = useApiAccess();

  const navigate = useNavigate();

  const {
    data: account,
    loading: isAccountLoading,
    error,
  } = useAccountDetailsQuery({
    variables: {
      address: id || '',
      sessionKey,
    },
  });

  useEffect(() => {
    if (error?.message.includes('not exist')) {
      navigate(routes[404]);
    }
  }, [error]);

  const accountData = AccountData({ data: account });

  const EXTRINSICS_COLUMNS = ExtrinsicsColumns();

  const { PageController, setTotalDataCount, pageSize, pageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { sortField, sortOrder, onSort } = useSortBy();

  const { data: extrinsicData, loading: isExtrinsicsLoading } = useGetExtrinsicsQuery({
    variables: {
      sessionKey,
      signer: id,
      page: { size: pageSize, number: pageNumber },
      ...(sortField && {
        sort: { field: sortField, order: sortOrder },
      }),
    },
    onCompleted(data) {
      setTotalDataCount(data.getExtrinsics.count);
    },
    fetchPolicy: 'cache-and-network',
    skip: !id,
  });

  return {
    id,
    accountData,
    isExtrinsicsLoading,
    extrinsicData: extrinsicData?.getExtrinsics,
    EXTRINSICS_COLUMNS,
    PageController,
    pageSize,
    onSort,
    sortOrder,
    isAccountLoading,
  };
};
export default useAccountDetails;

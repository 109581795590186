import { Tooltip } from '@analog/ui/src';
import { Card, Heading, SearchBox, Table } from 'components';
import { Skeleton } from 'components/Skeleton';
import { SortColumnsExtrinsics, arrayOfSize } from 'utils';

import { useExtrinsics } from './useExtrinsics';

const ExtrinsicsList = () => {
  const { extrinsicsData, PageController, isLoading, pageSize, sortOrder, onSort, COLUMNS } =
    useExtrinsics();

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        <Heading
          title="Extrinsics"
          subTitle="Examine all the details about the extrinsics, including extrinsic id, block time, hash, etc."
        />
        <SearchBox defaultSearchType={'Extrinsics'} />
      </div>
      <div className="flex flex-col mt-8 md:mt-[60px] gap-8 lg:gap-10">
        <Card extraClasses="!rounded-[20px] !p-6 !pb-5 flex flex-col gap-[18px]">
          <Table
            columns={COLUMNS}
            classNames={{
              row: 'grid gap-x-3 grid-cols-2 md:grid-cols-[minmax(110px,25%)_minmax(90px,20%)_minmax(90px,15%)_minmax(130px,30%)_minmax(100px,10%)_minmax(20px,2%)] border-none',
              columns: 'justify-start',
            }}
            data={!isLoading ? extrinsicsData : arrayOfSize(pageSize)}
            sortOptions={SortColumnsExtrinsics}
            sortOrder={sortOrder}
            onSort={onSort}
            isAccordion
            isLoading={isLoading}
          />
          <Skeleton.Provider isLoading={isLoading}>
            {(isLoading || extrinsicsData?.length) && PageController}
          </Skeleton.Provider>
        </Card>
      </div>
      <Tooltip id={'hash'} />
    </>
  );
};

export default ExtrinsicsList;

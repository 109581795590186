import { useEffect } from 'react';

import { useApiAccess } from 'contexts/AccessProvider';
import { useValidatorDetailsQuery } from 'gql';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'routes/routesConst';

import ValidatorData from './components/ValidatorData';

const useValidatorDetails = () => {
  const navigate = useNavigate();
  const { sessionKey } = useApiAccess();

  const { id } = useParams();

  const { data: validator, loading: isLoading } = useValidatorDetailsQuery({
    variables: { address: id as string, sessionKey },
    skip: !id,
  });

  useEffect(() => {
    if (!validator?.validatorDetails && !isLoading) {
      navigate(routes[404]);
    }
  }, [isLoading, navigate, validator?.validatorDetails]);

  const validatorData = ValidatorData({ validator: validator });

  return { isLoading, validator, validatorData };
};

export default useValidatorDetails;

import React from 'react';
import LeftCaret from '../../assets/left-caret.svg';
import RightCaret from '../../assets/right-caret.svg';
import { IconButton } from '../IconButton/IconButton';
import cn from '../../utils/classnames';

export type DetailNavigationTypes = {
  handlePrevDetails?: () => void;
  handleNextDetails?: () => void;
  disablePrev?: boolean;
  disableNext?: boolean;
  className?: string;
};

export const DetailsListNavigation = React.forwardRef<HTMLDivElement, DetailNavigationTypes>(
  ({ handlePrevDetails, handleNextDetails, disablePrev, disableNext, className }, ref) => {
    return (
      <div ref={ref} className={cn('flex gap-1', className)}>
        <IconButton
          className=" h-fit p-[6px] w-auto"
          variant="filled"
          icon={LeftCaret}
          iconClasses="w-[12px] h-[12px]"
          disabled={disablePrev}
          onClick={handlePrevDetails}
        />
        <IconButton
          className=" h-fit p-[6px] w-auto"
          variant="filled"
          icon={RightCaret}
          iconClasses="w-[12px] h-[12px]"
          disabled={disableNext}
          onClick={handleNextDetails}
        />
      </div>
    );
  }
);

DetailsListNavigation.displayName = 'DetailsListNavigation';

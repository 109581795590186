import { useState } from 'react';

import { CopyToClipboard } from '@analog/ui/src';
import ReactJson from '@uiw/react-json-view';
import { DynamicObject } from 'types';

import { Button } from './Button';
import VerticalTable from './VerticalTable';

interface JsonTableProps {
  index: string;
  jsonData: DynamicObject;
}

function JsonTable({ index, jsonData }: JsonTableProps) {
  const [isTable, setIsTable] = useState(true);

  if (!jsonData) return <></>;

  return (
    <>
      <div className="max-h-[350px] p-4 bg-[#111010] overflow-y-auto">
        {isTable ? (
          <div className="">{jsonData && <VerticalTable jsonData={jsonData} />}</div>
        ) : (
          <ReactJson
            value={jsonData}
            enableClipboard={false}
            style={{
              // @ts-ignore
              '--w-rjv-color': '#9cdcfe',
              '--w-rjv-key-number': '#ffffff',
              '--w-rjv-key-string': '#ffffff',
              '--w-rjv-background-color': '#111010',
              '--w-rjv-line-color': '#ffffff00',
              '--w-rjv-arrow-color': '#838383',
              '--w-rjv-edit-color': '#9cdcfe',
              '--w-rjv-info-color': '#9c9c9c7a',
              '--w-rjv-info-fontStyle': 'normal',
              '--w-rjv-fontStyle': 'normal',
              '--w-rjv-update-color': '#9cdcfe',
              '--w-rjv-copied-color': '#9cdcfe',
              '--w-rjv-copied-success-color': '#28a745',
              '--w-rjv-curlybraces-color': '#d4d4d4',
              '--w-rjv-colon-color': '#d4d4d4',
              '--w-rjv-brackets-color': '#d4d4d4',
              '--w-rjv-ellipsis-color': '#cb4b16',
              '--w-rjv-quotes-color': '#9cdcfe',
              '--w-rjv-quotes-string-color': '#D163E2',
              '--w-rjv-type-string-color': '#D163E2',
              '--w-rjv-type-int-color': '#b5cea8',
              '--w-rjv-type-float-color': '#b5cea8',
              '--w-rjv-type-bigint-color': '#b5cea8',
              '--w-rjv-type-boolean-color': '#569cd6',
              '--w-rjv-type-date-color': '#b5cea8',
              '--w-rjv-type-url-color': '#3b89cf',
              '--w-rjv-type-null-color': '#569cd6',
              '--w-rjv-type-nan-color': '#859900',
              '--w-rjv-type-undefined-color': '#569cd6',
            }}
            displayDataTypes={true}
          />
        )}
      </div>
      <div className="bg-[#111010] flex py-2 justify-end pr-3 gap-2  border-solid border-[#1f1f1f] border-0">
        <CopyToClipboard id={index} content={JSON.stringify(jsonData)}>
          <Button variant="darkThemeOutlined">Copy</Button>
        </CopyToClipboard>

        <Button variant="darkThemeOutlined" onClick={() => setIsTable(!isTable)}>
          {isTable ? 'View Code' : 'Decode'}
        </Button>
      </div>
    </>
  );
}

export default JsonTable;

import { useEffect } from 'react';

import { useApiAccess } from 'contexts/AccessProvider';
import { useBlockDetailsQuery } from 'gql';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'routes/routesConst';

import BlockOverViewDetails from './components/BlockOverViewDetails';

export const useBlockDetails = () => {
  const navigate = useNavigate();
  const { sessionKey } = useApiAccess();
  const { idOrHash } = useParams();

  const block = idOrHash?.startsWith('0x')
    ? { blockHash: idOrHash }
    : { blockNumber: +(idOrHash || -1) };

  const {
    data: blockDetailsData,
    loading: isLoading,
    error,
  } = useBlockDetailsQuery({ variables: { sessionKey, ...block } });

  useEffect(() => {
    if (error) {
      navigate(routes[404]);
    }
  }, [error, navigate]);

  const overviewDetails = BlockOverViewDetails({ blockDetailsData });

  return {
    isLoading,
    blockDetailsData,
    overviewDetails,
  };
};

import { ChevronDownIcon } from '@heroicons/react/24/outline';

import { HTMLAttributes } from 'react';
import cn from '../../utils/classnames';
import { Skeleton } from '../Skeleton/Skeleton';

export interface Props$Summary extends HTMLAttributes<HTMLDivElement> {
  classNames?: classNames<'removeButton' | 'removeIcon' | 'expandIcon' | 'content'>;
  onRemove?: () => void;
  isLoading?: boolean;
}

export type classNames<T extends string | never = never> = Partial<
  { base?: string } & Record<T, string | undefined>
>;

export interface Props$Details extends HTMLAttributes<HTMLDetailsElement> {
  open?: boolean;
}

export function Accordion({ open = false, ...props }: Props$Details) {
  return (
    <details open={open} {...props} className={cn('group w-full', props.className)}>
      {props.children}
    </details>
  );
}

export function Summary({ classNames, children, onRemove, isLoading, ...props }: Props$Summary) {
  return (
    <summary
      {...props}
      className={cn(
        'text-md cursor-pointer relative flex mt-[-1px] items-center  text-base',
        classNames?.base
      )}
    >
      {children}
      <div className="sm:w-8/12 w-6/12">
        {onRemove && (
          <button
            className={cn(
              'my-2 border-0 border-r border-solid border-neutral-200 px-3 text-black hover:text-neutral-600',
              classNames?.removeButton
            )}
            onClick={onRemove}
          ></button>
        )}
        <Skeleton.Loader className="h-5 w-5 !rounded-xl" containerClassName="leading-[0px]">
          <ChevronDownIcon
            className={cn(
              'justify-center text-pink transition-transform group-open:rotate-180 h-5 w-5 rounded-3xl p-[2px] mr-3 text-ellipsis border border-solid overflow-hidden whitespace-nowrap border-[#D163E2] flex items-center',
              classNames?.expandIcon
            )}
          />
        </Skeleton.Loader>
      </div>
    </summary>
  );
}

interface IconProps {
  className?: string;
}

export const SortArrowUpIcon = ({ className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="4"
    viewBox="0 0 6 4"
    fill="none"
    className={className}
  >
    <path
      d="M5.16667 3.08333L3.08333 1L1 3.08333"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.08333 1L5.16667 3.08333H1L3.08333 1Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

import { useApiAccess } from 'contexts/AccessProvider';
import { useTaskDetailsQuery } from 'gql';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { GMPTaskType } from 'types';

import TaskData from './components/TaskData';

const useGMPDetails = () => {
  const { id } = useParams();
  const { sessionKey } = useApiAccess();
  const navigate = useNavigate();

  const { data: taskDetailsData, loading: isLoading } = useTaskDetailsQuery({
    variables: { sessionKey, taskId: Number(id) },
    onError(error) {
      if (error.message) {
        navigate(routes[404]);
      }
    },
  });

  const taskData = TaskData({ taskDetailsData, isLoading });
  const title =
    taskDetailsData?.taskDetails.type === GMPTaskType.SendMessage ? 'Send Message' : 'Read Message';
  return { taskData, title, isLoading, id };
};
export default useGMPDetails;

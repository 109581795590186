const DetailsCard = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <div>
      <h3 className="text-xl md:text-2xl text-white mb-[2px] md:mb-[10px]">{title}</h3>
      <div className="flex flex-col md:gap-4 gap-[6px]">{children}</div>
    </div>
  );
};

export default DetailsCard;

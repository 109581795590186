import { GDPR_CONDITIONS, GDPR_RIGHTS } from './utils';

export const GDPRPrivacy = () => {
  return (
    <div>
      <h2 className="text-white text-2xl	md:text-[32px] md:leading-[40px] mb-3 md:mb-4">
        GDPR Privacy
      </h2>

      <div className="flex flex-col gap-8 md:gap-10 mb-8 md:mb-10">
        <div>
          <h3 className="text-xl md:text-2xl text-white mb-[2px] md:mb-[10px]">
            Legal Basis for Processing Personal Data under GDPR
          </h3>
          <div className="flex flex-col md:gap-4 gap-[6px]">
            <p>We may process Personal Data under the following conditions:</p>
            <div className="flex flex-col gap-[6px]">
              <ul className="list-disc flex flex-col gap-2 pl-6 md:pl-8">
                {GDPR_CONDITIONS.map(({ title, desc }) => {
                  return (
                    <li className="text-white">
                      {title} <span className="text-offWhite">{desc}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <p>
              In any case, the Company will gladly help to clarify the specific legal basis that
              applies to the processing, and in particular whether the provision of Personal Data is
              a statutory or contractual requirement, or a requirement necessary to enter into a
              contract.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-8 md:gap-10 mb-8 md:mb-10">
        <div>
          <h3 className="text-xl md:text-2xl text-white mb-[2px] md:mb-[10px]">
            Your Rights under the GDPR
          </h3>
          <div className="flex flex-col md:gap-4 gap-[6px]">
            <p>
              The Company undertakes to respect the confidentiality of Your Personal Data and to
              guarantee You can exercise Your rights.
            </p>
            <p>
              You have the right under this Privacy Policy, and by law if You are within the EU, to:
            </p>
            <div className="flex flex-col gap-[6px]">
              <ul className="list-disc flex flex-col gap-2 pl-6 md:pl-8">
                {GDPR_RIGHTS.map(({ title, desc }) => {
                  return (
                    <li className="text-white">
                      {title} <span className="text-offWhite">{desc}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <p>
              In any case, the Company will gladly help to clarify the specific legal basis that
              applies to the processing, and in particular whether the provision of Personal Data is
              a statutory or contractual requirement, or a requirement necessary to enter into a
              contract.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-8 md:gap-10">
        <div>
          <h3 className="text-xl md:text-2xl text-white mb-[2px] md:mb-[10px]">
            Exercising of Your GDPR Data Protection Rights
          </h3>
          <div className="flex flex-col md:gap-4 gap-[6px]">
            <p>
              You may exercise Your rights of access, rectification, cancellation and opposition by
              contacting Us. Please note that we may ask You to verify Your identity before
              responding to such requests. If You make a request, We will try our best to respond to
              You as soon as possible.
            </p>
            <p>
              You have the right to complain to a Data Protection Authority about Our collection and
              use of Your Personal Data. For more information, if You are in the European Economic
              Area (EEA), please contact Your local data protection authority in the EEA.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GDPRPrivacy;

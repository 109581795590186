import Cube3dIcon from './blocks/3dcube.svg';
import hashTagIcon from './blocks/hashtag.svg';
import timerIcon from './blocks/timer-stamp.svg';
import copyIcon from './copy.svg';
import dataArrowIcon from './extrinsics/data-arrows.svg';
import ParameterIcon from './extrinsics/parameters.svg';
import { default as SuccessIcon, default as tickCircleIcon } from './tick-circle.svg';
import gradientTickCircle from './tick-circle-gradient.svg';
import polygonIcon from './icons/home/polygon.svg';
import binanceIcon from './icons/home/binance.svg';
import bitcoinIcon from './icons/home/bitcoin.svg';
import ethereumIcon from './icons/home/ethereum.svg';
import optimismIcon from './icons/home/optimism.svg';
import offlineIcon from './icons/offline.svg';
import onlineIcon from './icons/online.svg';
import online2Icon from './icons/online-2.svg';
import astarIcon from './icons/home/astar.svg';
import layerIcon from './icons/layer.svg';
import calendarTickIcon from './icons/blocks/calendar-tick.svg';
import dataArrowsIcon from './icons/data-arrows.svg';
import profileIcon from './icons/profile.svg';
import hashTagOutlineIcon from './icons/blocks/hashtag-outline.svg';
import verifyIcon from './icons/validators/verify.svg';
import taskIcon from './icons/task.svg';

const allImgPaths = (() => ({
  taskIcon,
  astarIcon,
  layerIcon,
  verifyIcon,
  calendarTickIcon,
  profileIcon,
  hashTagOutlineIcon,
  dataArrowsIcon,
  online2Icon,
  dataArrowIcon,
  tickCircleIcon,
  gradientTickCircle,
  copyIcon,
  timerIcon,
  hashTagIcon,
  ParameterIcon,
  Cube3dIcon,
  SuccessIcon,
  polygonIcon,
  binanceIcon,
  bitcoinIcon,
  ethereumIcon,
  optimismIcon,
  offlineIcon,
  onlineIcon,
}))();

export default allImgPaths;

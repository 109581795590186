import DetailsCard from '../DetailsCard';

export const SeverabilityAndWaiver = () => {
  return (
    <div>
      <h2 className="text-white text-2xl	md:text-[32px] md:leading-[40px] mb-3 md:mb-4">
        Severability and Waiver
      </h2>

      <div className="flex flex-col gap-8 md:gap-10">
        <DetailsCard title="Severability">
          <p>
            If any provision of these Terms is held to be unenforceable or invalid, such provision
            will be changed and interpreted to accomplish the objectives of such provision to the
            greatest extent possible under applicable law and the remaining provisions will continue
            in full force and effect.
          </p>
        </DetailsCard>

        <DetailsCard title="Waiver">
          <p>
            Except as provided herein, the failure to exercise a right or to require performance of
            an obligation under these Terms shall not affect a party's ability to exercise such
            right or require such performance at any time thereafter nor shall the waiver of a
            breach constitute a waiver of any subsequent breach.
          </p>
        </DetailsCard>

        <DetailsCard title="Translation Interpretation">
          <p>
            These Terms and Conditions may have been translated if We have made them available to
            You on our Service. You agree that the original English text shall prevail in the case
            of a dispute.
          </p>
        </DetailsCard>

        <DetailsCard title="Changes to These Terms and Conditions">
          <p>
            We reserve the right, at Our sole discretion, to modify or replace these Terms at any
            time. If a revision is material We will make reasonable efforts to provide at least 30
            days' notice prior to any new terms taking effect. What constitutes a material change
            will be determined at Our sole discretion.
          </p>
          <p>
            By continuing to access or use Our Service after those revisions become effective, You
            agree to be bound by the revised terms. If You do not agree to the new terms, in whole
            or in part, please stop using the website and the Service.
          </p>
        </DetailsCard>

        <DetailsCard title="Contact Us">
          <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
          <div className="pl-5">
            <ul className="list-disc">
              <li>
                By email:{' '}
                <a href="mailto:legal@analog.one" className="text-link cursor-pointer">
                  legal@analog.one
                </a>
              </li>
            </ul>
          </div>
        </DetailsCard>

        <DetailsCard title="Compliance with Data Protection Act 2023 of Seychelles">
          <p>
            These terms and conditions are designed to comply with the Data Protection Act 2023 of
            Seychelles, which provides for the protection of individuals with regards to the
            processing of personal data and recognizes the right to privacy.
          </p>
          <p>
            By ensuring adherence to these terms and conditions, Analog Time demonstrates its
            commitment to protecting user data and complying with relevant data protection laws.
          </p>
        </DetailsCard>

        <DetailsCard title="Dispute Resolution">
          <p>
            Any disputes arising out of or relating to these terms and conditions shall be resolved
            through negotiation, mediation, or arbitration in Seychelles, as agreed upon by the
            parties involved.
          </p>
        </DetailsCard>

        <DetailsCard title="Data Transfers">
          <p>
            Personal data may be transferred to and processed in countries outside Seychelles,
            including locations where our remote team members and hiring managers are based. In such
            cases, we ensure that appropriate safeguards are in place to protect the data in
            accordance with applicable data protection laws.
          </p>
        </DetailsCard>

        <DetailsCard title="Prohibitions">
          <p>
            You represent and warrant that you are not a “Prohibited Person,” meaning that you are
            not (i) a citizen or resident of a geographic area in which use of cryptographic tokens
            is prohibited by applicable law, decree, regulation, treaty, or administrative act; (ii)
            a citizen or resident of, or located in, a geographic area that is subject to United
            States, United Kingdom, European Union, or United Nations sanctions or embargoes; or
            (iii) an individual, or an individual employed by or associated with an entity, that is
            identified on the U.S. Department of Commerce’s Denied Persons or Entity List, or the
            U.S. Department of Treasury’s Specially Designated Nationals or Blocked Persons Lists,
            or the U.S. Department of State’s Debarred Parties List or the sanctions lists adopted
            by the United Kingdom, European Union, or United Nation, as such lists may be amended
            from time to time; or (iv) a person who acts, directly or indirectly, for a senior
            foreign political figure, any member of a senior foreign political figure’s immediate
            family or any close associate of a senior foreign political figure, unless the Company,
            after being specifically notified by you in writing that you are such a person, conducts
            further due diligence, and determines that the purchase is permitted. If your country of
            residence or other circumstances change such that the above representations are no
            longer accurate, you immediately notify the Company.
          </p>
          <p>
            Further, you represent and warrant that you will not export or re-export, directly or
            indirectly, the Service and/or other information or materials provided by us hereunder,
            to any country for which any relevant jurisdiction requires any export license or other
            governmental approval at the time of export without first obtaining such license or
            approval. In particular, but without limitation, the Service may not be exported or
            re-exported to any Prohibited Person. You are responsible for and hereby agree to comply
            at your sole expense with all applicable laws and regulations.
          </p>
        </DetailsCard>

        <DetailsCard title="Governing Law">
          <p>
            The laws of Seychelles, excluding its conflicts of law rules, shall govern these Terms
            and Your use of the Service. Your use of the Application may also be subject to other
            local, state, national, or international laws.
          </p>
        </DetailsCard>

        <DetailsCard title="Final Provisions">
          <p>
            These Terms constitute the entire agreement between You and Analog Time regarding the
            use of the Service and supersede all prior and contemporaneous written or oral
            agreements between You and Analog Time.
          </p>
          <p>
            No waiver by Analog Time of any breach or default hereunder shall be deemed to be a
            waiver of any preceding or subsequent breach or default.
          </p>
          <p>
            If any provision of these Terms is held to be invalid or unenforceable, such provision
            shall be struck and the remaining provisions shall be enforced to the fullest extent
            under law.
          </p>
          <p>
            The headings in these Terms are for convenience only and have no legal or contractual
            effect.
          </p>
        </DetailsCard>
      </div>
    </div>
  );
};

import { useEffect, useRef, useState } from 'react';

import allImgPaths from 'assets/allImgPaths';
import { useOutsideClick } from 'hooks';
import { classNames, classes } from 'utils';

interface DropdownProps {
  options: string[];
  onSelect: (selectedOption: string) => void;
  defaultSelected?: string;
  withBorder?: boolean;
  classNames?: classNames<'container' | 'selector' | 'optionContainer' | 'option'>;
}
const Dropdown = ({
  options,
  onSelect,
  defaultSelected = options[0],
  withBorder = true,
  classNames,
}: DropdownProps) => {
  const impactRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [isOpen, setIsOpen] = useState(false);

  useOutsideClick(impactRef, () => setIsOpen(false));

  useEffect(() => {
    setSelectedOption(defaultSelected);
  }, [defaultSelected]);

  const handleSelect = (option: string) => {
    setSelectedOption(option);
    onSelect(option);
    closeDropdown();
  };

  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  return (
    <div
      ref={impactRef}
      className={classes('inline-block text-left md:relative', classNames?.base)}
    >
      <button
        type="button"
        onClick={toggleDropdown}
        className={classes(
          'inline-flex justify-center items-center text-sm  text-white  rounded-[25px] px-[13px] py-2 h-[36px]',
          withBorder && 'shadow-[0px_0px_0px_2px_#ffffff14]',
          classNames?.selector
        )}
      >
        {selectedOption === 'members' ? 'Chronicles' : selectedOption}
        <img
          src={allImgPaths.caretIcon}
          alt="arrow"
          className={`w-[18px] h-[18px] transition-transform ${
            isOpen ? 'transform rotate-0' : 'rotate-180'
          }`}
        />
      </button>

      {isOpen && (
        <div
          className={classes(
            'origin-top-right absolute left-0 z-50 mt-2 rounded-xl bg-[#141414] border border-solid border-[rgba(255,255,255,0.1)] w-full',
            classNames?.container
          )}
        >
          <div className={classes('flex flex-col py-[0px] px-4', classNames?.optionContainer)}>
            {options.map((option, index) => {
              return (
                <button
                  key={option}
                  onClick={() => handleSelect(option)}
                  className={classes(
                    'block text-left text-sm text-white hover:text-gray-300 border border-solid py-[14px] border-[#1F1F1F] border-x-0 border-b-0',
                    index == 0 && 'border-t-0 ',
                    classNames?.option
                  )}
                >
                  <div className="flex items-center gap-[7px] justify-between leading-[20px]">
                    {option}
                    {selectedOption.toLowerCase() === option.toLowerCase() ||
                    (selectedOption === 'members' && option === 'Chronicles') ? (
                      <div className="rounded-full border-[6px] border-[#E563F9]">
                        <div className="p-1"></div>
                      </div>
                    ) : (
                      <div className="rounded-full border-[10px] border-white/[0.04]">
                        <div className="p-0"></div>
                      </div>
                    )}
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;

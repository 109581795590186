import allImgPaths from 'assets/allImgPaths';
import { IconCard, Text } from 'components';
import { Skeleton } from 'components/Skeleton';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';
import { timeAgo } from 'utils';

const LatestExtrinsicsColumn = () => {
  const navigate = useNavigate();
  return [
    {
      title: 'id',
      key: 'id',
      shouldWrap: false,

      render: (data: ColumnData) => (
        <div className="flex gap-[10px] col-span-2 md:col-span-1">
          <IconCard icon={allImgPaths.dataArrowsIcon} iconName="Layer" />
          <div className={'flex flex-col overflow-hidden'}>
            <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
              <Text
                extraClass="text-pink text-sm cursor-pointer w-fit"
                onClick={() => {
                  navigate(`${routes.extrinsic}/${data[data.columnKey]}`);
                }}
              >
                {data[data.columnKey]}
              </Text>
            </Skeleton.Loader>
            <Skeleton.Loader className="lg:w-[100px] w-[50px]" containerClassName="h-5">
              <Text extraClass="text-xs text-offWhite">
                {data?.created_at && timeAgo(new Date(data?.created_at))}
              </Text>
            </Skeleton.Loader>
          </div>
        </div>
      ),
    },

    {
      title: 'Type',
      key: 'method',

      render: (data: ColumnData) => (
        <div className={'flex flex-col overflow-hidden'}>
          <Skeleton.Loader className="lg:w-[90px] w-[50px]" containerClassName="h-5">
            <Text extraClass="text-white text-sm">{data[data.columnKey] || '-'}</Text>
          </Skeleton.Loader>
          <Skeleton.Loader className="lg:w-[80px] w-[40px]" containerClassName="h-5">
            <Text extraClass="text-xs text-offWhite">Type</Text>
          </Skeleton.Loader>
        </div>
      ),
    },
    {
      title: 'hash',
      key: 'hash',

      render: (data: ColumnData) => (
        <div className="flex lg:block flex-col">
          <Skeleton.Loader className="lg:w-[110px] w-[60px]" containerClassName="h-5">
            <div className="flex gap-1 items-end">
              <span className="text-white text-sm">
                {data[data.columnKey]
                  ? data[data.columnKey].substring(2, 10) +
                    '...' +
                    data[data.columnKey].substring(data[data.columnKey].length - 7)
                  : '-'}
              </span>
            </div>
          </Skeleton.Loader>
          <Skeleton.Loader className="lg:w-[90px] w-[50px]" containerClassName="h-5">
            <span className="text-xs text-offWhite">Extrinsic Hash</span>
          </Skeleton.Loader>
        </div>
      ),
    },
  ];
};
export default LatestExtrinsicsColumn;

import { ReactNode } from 'react';

import { classes } from 'utils';

type RowWrapperProps = {
  children: ReactNode;
  className: string | undefined;
};
const RowWrapper = ({ children, className }: RowWrapperProps) => {
  return (
    <div
      className={classes(
        'gap-3 border-solid border-[1px] border-t-0 border-l-0 border-r-0 border-lightGray md:h-[70px] items-center first:pt-0 py-4 md:py-0 last:border-lightGray',
        className
      )}
    >
      {children}
    </div>
  );
};

export default RowWrapper;

import { useEffect } from 'react';

import { useApiAccess } from 'contexts/AccessProvider';
import { useMemberDetailsQuery } from 'gql';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'routes/routesConst';

import ChronicleData from './components/ChronicleData';

const useChroniclesDetails = () => {
  const { id } = useParams();

  const { sessionKey } = useApiAccess();

  const navigate = useNavigate();

  const {
    data: chroniclesData,
    loading: isLoading,
    error,
  } = useMemberDetailsQuery({
    variables: {
      accountId: id || '',
      sessionKey,
    },
  });

  useEffect(() => {
    if (error?.message.includes('not exist')) {
      navigate(routes[404]);
    }
  }, [error]);

  const chronicleData = ChronicleData({ chroniclesData });

  return { chronicleData, isLoading, id };
};
export default useChroniclesDetails;

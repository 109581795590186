import { HashIcon, Icon } from '@analog/ui';
import allImgPaths from 'assets/allImgPaths';
import { ValidatorDetailsQuery } from 'gql';
import moment from 'moment';

interface ValidatorDataProps {
  validator: ValidatorDetailsQuery | undefined;
}

const ValidatorData = ({ validator }: ValidatorDataProps) => {
  const transformToNumber = (td: string) => Number(td).toFixed(2);

  return [
    {
      icon: <Icon icon={allImgPaths.globalSearchIcon} />,
      label: 'Operator Address',
      value: (
        <HashIcon copyable value={validator?.validatorDetails?.address} className="text-pink" />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.profileIcon} />,
      label: 'Name',
      value: <>{validator?.validatorDetails?.name}</>,
    },
    {
      icon: <Icon icon={allImgPaths.flagsIcon} />,
      label: 'Rank',
      value: validator?.validatorDetails?.rank,
    },
    {
      icon: <Icon icon={allImgPaths.ageIcon} />,
      label: 'Discovered',
      value: <>{moment(Number(validator?.validatorDetails?.discovered)).fromNow()}</>,
    },
    {
      icon: <Icon icon={allImgPaths.tickCircleIcon} />,
      label: 'Status',
      value: validator?.validatorDetails?.status,
    },
    {
      icon: <Icon icon={allImgPaths.phaseIcon} />,
      label: 'Valid',
      value: (
        <span style={{ color: validator?.validatorDetails?.valid ? 'inherit' : 'red' }}>
          {validator?.validatorDetails?.valid ? 'Valid' : 'Invalid'}
        </span>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.infoIcon} />,
      label: 'Faults',
      value: validator?.validatorDetails?.faults,
    },
    {
      icon: <Icon icon={allImgPaths.chainIcon} />,
      label: 'Inclusion',
      value: <>{validator?.validatorDetails?.inclusion}</>,
    },
    {
      icon: <Icon icon={allImgPaths.dataSourceIcon} />,
      label: 'Score',
      value: validator?.validatorDetails?.score || 0,
    },
    {
      icon: <Icon icon={allImgPaths.addIcon} />,
      label: 'Commission',
      value: <>{transformToNumber(validator?.validatorDetails?.commission!)} %</>,
    },
    {
      icon: <Icon icon={allImgPaths.rewardIcon} />,
      label: 'Rewards',
      value: <span>{transformToNumber(validator?.validatorDetails?.rewards || '0')} TANLOG</span>,
    },
  ];
};
export default ValidatorData;

import { twMerge } from 'tailwind-merge';

// cn function is used for for constructing className strings conditionally.

// here is examples:

// // Strings (variadic)
// cn('moon', true && 'bar', 'bat');
// //=> 'moon bar bat'

// // Objects
// cn({ moon:true, earth:false, bat:isTrue() });
// //=> 'moon bat'

// // Objects (variadic)
// cn({ moon:true }, { bar:false }, null, { '--moonbar':'hello' });
// //=> 'moon --moonbar'

// // Arrays
// cn(['moon', 0, false, 'bat']);
// //=> 'moon bat'

// // Arrays (variadic)
// cn(['moon'], ['', 0, false, 'venus'], [['bat', [['hello'], 'there']]]);
// //=> 'moon venus bat hello there'

// // Kitchen sink (with nesting)
// cn('moon', [1 && 'bat', { venus:false, pot:null }, ['hello', ['world']]], 'cya');
// //=> 'moon bat hello world cya'

function toVal(mix: any): string {
  if (typeof mix === 'string' || typeof mix === 'number') {
    return mix.toString();
  }
  if (Array.isArray(mix)) {
    return mix
      .filter(Boolean) // filter out falsy values
      .map(toVal) // recursively apply `toVal` on each item
      .join(' '); // join the results with a space
  }
  if (typeof mix === 'object' && mix !== null) {
    return Object.keys(mix)
      .filter((key) => mix[key]) // only keep truthy values
      .join(' '); // join keys with a space
  }
  return '';
}

export function cn(...args: any[]): string {
  return twMerge(
    args
      .map(toVal) // apply `toVal` on each argument
      .filter(Boolean) // filter out any empty or falsy results
      .join(' ')
  ); // join the non-empty results with a space
}
export default cn;

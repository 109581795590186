import { useApiAccess } from 'contexts/AccessProvider';
import { useListMembersQuery } from 'gql';
import { useSortBy } from 'hooks';
import { useNavigate } from 'react-router-dom';

const useMembersTable = () => {
  const navigate = useNavigate();
  const {
    sortField: membersSortField,
    sortOrder: membersSortOrder,
    onSort: membersOnSort,
  } = useSortBy();
  const { sessionKey } = useApiAccess();

  const { data: membersData, loading: membersLoading } = useListMembersQuery({
    variables: {
      sessionKey,
      page: { size: 6, number: 1 },
      ...(membersSortField && {
        sort: { field: membersSortField, order: membersSortOrder },
      }),
    },
  });
  return { membersLoading, membersData, membersSortOrder, membersOnSort, navigate };
};
export default useMembersTable;

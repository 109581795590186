import { Skeleton } from 'components/Skeleton';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { getIcon, parseJson } from 'utils';

const RenderNetwork = ({ network, element }: { network: string; element?: 'first' | 'second' }) => {
  const parsedData = parseJson(network);

  let dataToRender = parsedData;
  if (element === 'first') {
    dataToRender = parsedData.slice(0, 1);
  } else if (element === 'second') {
    dataToRender = parsedData.slice(1, 2);
  }

  return dataToRender.length ? (
    dataToRender?.map((value: string, index: number) => (
      <TableColumns
        key={index}
        beforeImagePath={value && getIcon(value)}
        value={value + (dataToRender.length >= 2 && index < dataToRender.length - 1 ? ',' : '')}
        textColor={FontColors.WHITE}
        classNames={{
          container: '!w-fit',
          base: '!w-fit',
          content: 'capitalize',
          skeletonText: 'h-4 w-12',
        }}
      />
    ))
  ) : (
    <Skeleton.Loader className="h-4 w-10" containerClassName="flex">
      -
    </Skeleton.Loader>
  );
};

export default RenderNetwork;

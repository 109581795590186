import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { SortOrderType, useListBlocksQuery } from 'gql';
import { useSortBy } from 'hooks';
import usePagination from 'hooks/usePagination';

import BlockColumn from './components/BlockColumns';

function useBlockList() {
  const { sessionKey } = useApiAccess();
  const { PageController, setTotalDataCount, pageSize, pageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { sortField, sortOrder, onSort } = useSortBy();

  const modifiedSortOrder =
    sortField === 'created_at' && sortOrder === SortOrderType.Asc
      ? SortOrderType.Desc
      : sortField === 'created_at' && sortOrder === SortOrderType.Desc
      ? SortOrderType.Asc
      : sortOrder;

  const { data: blockData, loading: isLoading } = useListBlocksQuery({
    variables: {
      sessionKey,
      page: { size: pageSize, number: pageNumber },
      ...(sortField && {
        sort: { field: sortField, order: modifiedSortOrder },
      }),
    },
    onCompleted(data) {
      setTotalDataCount(data.listBlocks.count);
    },
    fetchPolicy: 'cache-and-network',
  });

  const COLUMNS = BlockColumn();

  return {
    COLUMNS,
    PageController,
    blockData: blockData?.listBlocks.data,
    isLoading,
    pageSize,
    sortOrder,
    onSort,
  };
}

export default useBlockList;

import { ChangeEventHandler } from 'react';

interface SwitchesProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  isChecked: boolean;
  counts: { eventCount: number | string; extrinsicsCount: number | string };
}

const Switches = ({ onChange, isChecked, counts }: SwitchesProps) => {
  return (
    <>
      <label className="shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-[25px] p-1 border-[1px] border-solid border-[#FFFFFF33] w-full md:w-auto">
        <input type="checkbox" className="sr-only" checked={isChecked} onChange={onChange} />
        <span
          className={`flex items-center space-x-[6px] rounded-[32px] py-[5px] px-4 text-sm font-medium text-white w-full md:w-auto justify-center ${
            !isChecked ? 'text-primary bg-[#1B1B1B]' : ''
          }`}
        >
          Extrinsics&nbsp;
          <span className="text-offWhite">{counts.extrinsicsCount}</span>
        </span>
        <span
          className={`flex items-center space-x-[6px] rounded-[32px] py-[5px] px-4 text-sm font-medium text-white w-full md:w-auto justify-center ${
            isChecked ? 'text-primary bg-[#1B1B1B]' : ''
          }`}
        >
          Events&nbsp;
          <span className="text-offWhite">{counts.eventCount}</span>
        </span>
      </label>
    </>
  );
};

export default Switches;

import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { SortOrderType, useListExtrinsicsQuery } from 'gql';
import { useSortBy } from 'hooks';
import usePagination from 'hooks/usePagination';

import ExtrinsicsColumn from './components/ExtrinsicsColumn';

export function useExtrinsics() {
  const { sessionKey } = useApiAccess();
  const { PageController, setTotalDataCount, pageSize, pageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { sortField, sortOrder, onSort } = useSortBy();

  const modifiedSortOrder =
    sortField === 'created_at' && sortOrder === SortOrderType.Asc
      ? SortOrderType.Desc
      : sortField === 'created_at' && sortOrder === SortOrderType.Desc
      ? SortOrderType.Asc
      : sortOrder;

  const { data: extrinsicData, loading: isLoading } = useListExtrinsicsQuery({
    variables: {
      sessionKey,
      page: { size: pageSize, number: pageNumber },
      ...(sortField && {
        sort: { field: sortField, order: modifiedSortOrder },
      }),
    },
    onCompleted(data) {
      setTotalDataCount(data.listExtrinsics.count);
    },
    fetchPolicy: 'cache-and-network',
  });

  const COLUMNS = ExtrinsicsColumn();
  return {
    PageController,
    extrinsicsData: extrinsicData?.listExtrinsics.data,
    isLoading,
    pageSize,
    pageNumber,
    sortOrder,
    onSort,
    COLUMNS,
  };
}

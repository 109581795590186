import { HTMLAttributes } from 'react';

import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Skeleton } from 'components/Skeleton';
import { classNames, classes } from 'utils';

export interface SummaryProps extends HTMLAttributes<HTMLDivElement> {
  classNames?: classNames<'removeButton' | 'removeIcon' | 'expandIcon' | 'content'>;
  onRemove?: () => void;
  isLoading?: boolean;
}

export interface DetailsProps extends HTMLAttributes<HTMLDetailsElement> {
  open?: boolean;
}

export function Details({ open = true, ...props }: DetailsProps) {
  return (
    <details
      open={open}
      {...props}
      className={classes('group border border-[#ffffff1f] ', props.className)}
    >
      {props.children}
    </details>
  );
}

export function Summary({ classNames, children, onRemove, ...props }: SummaryProps) {
  return (
    <summary
      {...props}
      className={classes(
        'text-md relative flex mt-[-1px] items-center border-0 border-b border-solid border-[#ffffff1f] py-3.5 text-base',
        classNames?.base
      )}
    >
      <div className={classes('flex flex-1 items-center', classNames?.content)}>{children}</div>
      {onRemove && (
        <button
          className={classes(
            'my-2 border-0 border-r border-solid border-neutral-200 px-3 text-black hover:text-neutral-600',
            classNames?.removeButton
          )}
          onClick={onRemove}
        ></button>
      )}
      <Skeleton.Loader className="h-5 w-5" containerClassName="h-5">
        <ChevronDownIcon
          className={classes(
            'justify-center text-black transition-transform group-open:rotate-180 h-5 w-5 rounded-3xl p-[2px] text-ellipsis border border-solid overflow-hidden whitespace-nowrap border-[#D163E2] flex items-center',
            classNames?.expandIcon
          )}
        />
      </Skeleton.Loader>
    </summary>
  );
}

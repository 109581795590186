import { VariantProps, tv } from 'tailwind-variants';
import { classes } from 'utils';

const statusBadge = tv({
  base: 'text-white capitalize px-2.5 py-0.5 w-fit rounded-full bg-opacity-[0.8] hover:bg-opacity-100 text-sm',
  variants: {
    badgeColor: {
      blue: 'bg-[#1F44A6]',
      yellow: 'bg-[#A7A010]',
      green: 'bg-[#31AC2E]',
    },
    disabled: {
      true: 'opacity-50',
    },
  },
  defaultVariants: {
    badgeColor: 'blue',
  },
});

export interface StatusBadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof statusBadge> {
  label?: string;
}

const Badge = ({ badgeColor, label, disabled, className, ...rest }: StatusBadgeProps) => {
  return (
    <div className={classes(statusBadge({ badgeColor, disabled }), className)} {...rest}>
      {label}
    </div>
  );
};

export default Badge;

import { useEffect } from 'react';

import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import {
  ListBlocksWithExtrinsicsDocument,
  useListBlocksWithExtrinsicsQuery,
  useNewBlocksSubscription,
} from 'gql';
import { useWindowSize } from 'hooks';

import LatestBlockColumn from './LatestBlockColumn';

const useLatestBlock = () => {
  const { width } = useWindowSize();
  const { sessionKey } = useApiAccess();

  const { data: blocksList, loading: blocksListLoading } = useListBlocksWithExtrinsicsQuery({
    variables: { sessionKey, page: { size: DEFAULT_PAGE_SIZE } },
    fetchPolicy: 'cache-and-network',
  });

  useNewBlocksSubscription({
    onData(options) {
      const updatedData = [
        {
          ...options.data.data?.newBlocks?.block,
          extrinsicsCount: options.data.data?.newBlocks?.extrinsicsCount as number,
        },
        ...(blocksList?.listBlocks.data || []),
      ];
      updatedData.splice(100);
      options.client.writeQuery({
        query: ListBlocksWithExtrinsicsDocument,
        data: {
          listBlocks: {
            ...blocksList?.listBlocks,
            data: updatedData,
          },
        },
        variables: {
          sessionKey,
          page: { size: DEFAULT_PAGE_SIZE },
        },
        broadcast: true,
      });
    },
  });

  const COLUMNS = LatestBlockColumn();

  useEffect(() => {
    const x = document?.getElementById('blocks')?.scrollTop;
    const height = width < 768 ? 125 : 70;
    const scrollByHeight =
      x && x >= 0 - height && x != -height
        ? document?.getElementById('blocks')?.scrollHeight || 0
        : height;

    document?.getElementById('blocks')?.scrollBy({
      top: -scrollByHeight,
      behavior: 'smooth',
    });
  }, [blocksList, width]);
  return { COLUMNS, blocksList, blocksListLoading };
};
export default useLatestBlock;

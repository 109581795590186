import DetailsCard from '../DetailsCard';

export const Content = () => {
  return (
    <div>
      <h2 className="text-white text-2xl	md:text-[32px] md:leading-[40px] mb-3 md:mb-4">Content</h2>
      <div className="flex flex-col gap-8 md:gap-10">
        <DetailsCard title="Your Right to Post Content">
          <p>
            Our Service allows You to post Content. You are responsible for the Content that You
            post to the Service, including its legality, reliability, and appropriateness.
          </p>
          <p>
            By posting Content to the Service, You grant Us the right and license to use, modify,
            publicly perform, publicly display, reproduce, and distribute such Content on and
            through the Service. You retain any and all of Your rights to any Content You submit,
            post, or display on or through the Service and You are responsible for protecting those
            rights. You agree that this license includes the right for Us to make Your Content
            available to other users of the Service, who may also use Your Content subject to these
            Terms.
          </p>
          <p>
            You represent and warrant that: (i) the Content is Yours (You own it) or You have the
            right to use it and grant Us the rights and license as provided in these Terms, and (ii)
            the posting of Your Content on or through the Service does not violate the privacy
            rights, publicity rights, copyrights, contract rights, or any other rights of any
            person.
          </p>
        </DetailsCard>

        <DetailsCard title="Content Restrictions">
          <p>
            The Company is not responsible for the content of the Service's users. You expressly
            understand and agree that You are solely responsible for the Content and for all
            activity that occurs under Your account, whether done so by You or any third person
            using Your account.
          </p>
          <p>
            You may not transmit any Content that is unlawful, offensive, upsetting, intended to
            disgust, threatening, libelous, defamatory, obscene, or otherwise objectionable.
            Examples of such objectionable Content include, but are not limited to, the following:
          </p>
          <div className="pl-5">
            <ul className="list-disc flex flex-col gap-[6px]">
              <li>Unlawful or promoting unlawful activity.</li>
              <li>
                Defamatory, discriminatory, or mean-spirited content, including references or
                commentary about religion, race, sexual orientation, gender, national/ethnic origin,
                or other targeted groups.
              </li>
              <li>
                Spam, machine – or randomly – generated, constituting unauthorized or unsolicited
                advertising, chain letters, any other form of unauthorized solicitation, or any form
                of lottery or gambling.
              </li>
              <li>
                Containing or installing any viruses, worms, malware, trojan horses, or other
                content that is designed or intended to disrupt, damage, or limit the functioning of
                any software, hardware or telecommunications equipment or to damage or obtain
                unauthorized access to any data or other information of a third person.
              </li>
              <li>
                Infringing on any proprietary rights of any party, including patent, trademark,
                trade secret, copyright, right of publicity, or other rights.
              </li>
              <li>
                Impersonating any person or entity including the Company and its employees or
                representatives.
              </li>
              <li>Violating the privacy of any third person.</li>
              <li>False information and features.</li>
            </ul>
          </div>
          <p>
            The Company reserves the right, but not the obligation, to, in its sole discretion,
            determine whether or not any Content is appropriate and complies with these Terms,
            refuse or remove this Content. The Company further reserves the right to make formatting
            and edits and change the manner of any Content. The Company can also limit or revoke the
            use of the Service if You post such objectionable Content. As the Company cannot control
            all content posted by users and/or third parties on the Service, you agree to use the
            Service at your own risk. You understand that by using the Service You may be exposed to
            content that You may find offensive, indecent, incorrect, or objectionable, and You
            agree that under no circumstances will the Company be liable in any way for any content,
            including any errors or omissions in any content, or any loss or damage of any kind
            incurred as a result of your use of any content.
          </p>
        </DetailsCard>

        <DetailsCard title="Content Backups">
          <p>
            The Company does not guarantee there will be no loss or corruption of data. The Company
            will provide support and attempt to troubleshoot any known or discovered issues that may
            affect the Content. But You acknowledge that the Company has no liability related to the
            integrity of Content or the failure to successfully restore Content to a usable state.
          </p>
          <p>
            You agree to maintain a complete and accurate copy of any Content in a location
            independent of the Service.
          </p>
        </DetailsCard>
      </div>
    </div>
  );
};

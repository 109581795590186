import { useEffect } from 'react';

import { useApiAccess } from 'contexts/AccessProvider';
import {
  ListExtrinsicsForSubscriptionDocument,
  useListExtrinsicsForSubscriptionQuery,
  useNewExtrinsicsSubscription,
} from 'gql';
import { useWindowSize } from 'hooks';
import { useNavigate } from 'react-router-dom';

import LatestExtrinsicsColumn from './LatestExtrinsicsColumn';

const useLatestExtrinsics = () => {
  const Navigate = useNavigate();
  const { sessionKey } = useApiAccess();
  const { width } = useWindowSize();

  const { data: extrinsicsList, loading: extrinsicsListLoading } =
    useListExtrinsicsForSubscriptionQuery({
      variables: { sessionKey },
      fetchPolicy: 'cache-and-network',
    });

  useNewExtrinsicsSubscription({
    onData(options) {
      const updatedData = [
        options.data.data?.newExtrinsics,
        ...(extrinsicsList?.listExtrinsics?.data || []),
      ];
      updatedData.splice(100);
      options.client.writeQuery({
        query: ListExtrinsicsForSubscriptionDocument,
        data: {
          listExtrinsics: {
            ...extrinsicsList?.listExtrinsics,
            data: updatedData,
          },
        },
        variables: {
          sessionKey,
        },
        broadcast: true,
      });
    },
  });

  const COLUMNS = LatestExtrinsicsColumn();

  useEffect(() => {
    const x = document?.getElementById('extrinsics')?.scrollTop;
    const height = width < 768 ? 125 : 70;
    const scrollByHeight =
      typeof x == 'number' && extrinsicsListLoading
        ? document?.getElementById('extrinsics')?.scrollHeight || 0
        : height;
    document?.getElementById('extrinsics')?.scrollBy({
      top: -scrollByHeight,
      behavior: 'smooth',
    });
  }, [extrinsicsList, width]);
  return { Navigate, extrinsicsListLoading, COLUMNS, extrinsicsList };
};

export default useLatestExtrinsics;

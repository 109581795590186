import { useEffect, useState } from 'react';

import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { ListTasksDocument, Maybe, useListTasksQuery, useTaskSubscription } from 'gql';
import { useSortBy } from 'hooks';
import { GMPTaskType, TaskType } from 'types';
const useTaskTable = () => {
  const { sessionKey } = useApiAccess();
  const [taskListData, setTaskListData] = useState<Maybe<TaskType | undefined>[]>();
  const [phase, setPhase] = useState('');

  const { sortField: tasksSortField, sortOrder: tasksSortOrder, onSort: tasksOnSort } = useSortBy();
  const { data: taskList, loading: tasksLoading } = useListTasksQuery({
    variables: {
      sessionKey,
      page: { size: DEFAULT_PAGE_SIZE },
      ...(tasksSortField && {
        sort: { field: tasksSortField, order: tasksSortOrder },
      }),
      phase,
      taskType: GMPTaskType.SendMessage,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    taskList?.listTasks?.data && setTaskListData(taskList.listTasks.data);
  }, [taskList]);

  useTaskSubscription({
    onData(options) {
      const updatedData = [options.data.data?.newTasks?.task, ...(taskListData || [])];
      updatedData.splice(100);
      setTaskListData(updatedData);
      options.client.writeQuery({
        query: ListTasksDocument,
        data: {
          ...taskList,
          taskList: {
            ...taskList?.listTasks,
            data: updatedData,
          },
        },
        variables: {
          sessionKey,
          page: { size: DEFAULT_PAGE_SIZE },
        },
        broadcast: true,
      });
    },
  });
  return { taskListData, tasksSortOrder, tasksOnSort, tasksLoading, setPhase };
};

export default useTaskTable;

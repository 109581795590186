import { CopyToClipboard, Icon, NetworkDetails, TruncateString } from '@analog/ui';
import allImgPaths from 'assets/allImgPaths';
import { Text } from 'components';
import JsonTable from 'components/common/JsonTable';
import { Skeleton } from 'components/Skeleton';
import { FontColors } from 'consts';
import { TaskDetailsQuery } from 'gql';
import { useWindowSize } from 'hooks';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { GMPTaskType } from 'types';
import { getLinkFromNetwork, timeAgo } from 'utils';

interface TaskDataProps {
  taskDetailsData: TaskDetailsQuery | undefined;
  isLoading: boolean;
}

const TaskData = ({ taskDetailsData, isLoading }: TaskDataProps) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  return [
    {
      icon: <Icon icon={allImgPaths.taskIcon} />,
      label: 'Task ID',
      value: <div className="text-pink">{taskDetailsData?.taskDetails.id}</div>,
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Created At',
      value: taskDetailsData?.taskDetails?.created_at
        ? `${timeAgo(new Date(taskDetailsData?.taskDetails?.created_at))} (${moment
            .utc(taskDetailsData?.taskDetails?.created_at)
            .format('MMM-D-YYYY h:mm:ss A +UTC')})`
        : '-',
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Updated At',
      value: taskDetailsData?.taskDetails?.updated_at
        ? `${timeAgo(new Date(taskDetailsData?.taskDetails?.updated_at))} (${moment
            .utc(taskDetailsData?.taskDetails?.updated_at)
            .format('MMM-D-YYYY h:mm:ss A +UTC')})`
        : '-',
    },
    {
      icon: <Icon icon={allImgPaths.transferIcon} />,
      label: 'Proposed At Block',
      value: (
        <Text
          extraClass="cursor-pointer"
          color={FontColors.PURPLE}
          onClick={() =>
            taskDetailsData?.taskDetails?.proposed_at_block &&
            navigate(`${routes.block}/${taskDetailsData?.taskDetails?.proposed_at_block}`)
          }
        >
          {taskDetailsData?.taskDetails?.proposed_at_block}
        </Text>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.clipboardTickIcon} />,
      label: 'Modified At Block',
      value: taskDetailsData?.taskDetails?.modified_at_block ? (
        <Text
          extraClass="cursor-pointer"
          color={FontColors.PURPLE}
          onClick={() =>
            taskDetailsData?.taskDetails?.modified_at_block &&
            navigate(`${routes.block}/${taskDetailsData?.taskDetails?.modified_at_block}`)
          }
        >
          {taskDetailsData?.taskDetails?.modified_at_block}
        </Text>
      ) : (
        '-'
      ),
    },

    ...(taskDetailsData?.taskDetails.shardId || isLoading
      ? [
          {
            icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
            label: 'Shard ID',
            value: (
              <div
                className="text-pink cursor-pointer"
                onClick={() => {
                  taskDetailsData?.taskDetails.shardId &&
                    navigate(`${routes.shard}/${taskDetailsData.taskDetails.shardId.toString()}`);
                }}
              >
                {taskDetailsData?.taskDetails.shardId}
              </div>
            ),
          },
        ]
      : []),

    ...(taskDetailsData?.taskDetails.phase || isLoading
      ? [
          {
            icon: <Icon icon={allImgPaths.phaseIcon} />,
            label: 'Phase',
            value: <div className="text-white">{taskDetailsData?.taskDetails.phase}</div>,
          },
        ]
      : []),

    ...(taskDetailsData?.taskDetails.type === GMPTaskType.SendMessage
      ? [
          ...(taskDetailsData?.taskDetails.salt || isLoading
            ? [
                {
                  icon: <Icon icon={allImgPaths.cube3DIcon} />,
                  label: 'Salt',
                  value: (
                    <>
                      <div className="flex flex-row gap-[6px] items-center text-white">
                        <TruncateString text={taskDetailsData?.taskDetails.salt as string} />
                        {taskDetailsData?.taskDetails.salt && (
                          <CopyToClipboard
                            icon
                            content={taskDetailsData?.taskDetails.salt}
                            id={taskDetailsData?.taskDetails.salt}
                          />
                        )}
                      </div>
                    </>
                  ),
                },
              ]
            : []),

          ...(taskDetailsData?.taskDetails.sourceNetwork || isLoading
            ? [
                {
                  icon: <Icon icon={allImgPaths.sourceNetworkIcon} />,
                  label: 'Source Network',
                  value: taskDetailsData?.taskDetails.type === GMPTaskType.SendMessage && (
                    <div className="flex gap-1 text-white">
                      {taskDetailsData?.taskDetails.sourceNetwork ? (
                        <NetworkDetails data={taskDetailsData?.taskDetails.sourceNetwork} />
                      ) : (
                        <Skeleton.Loader className="h-4 w-10">-</Skeleton.Loader>
                      )}
                    </div>
                  ),
                },
              ]
            : []),

          ...(taskDetailsData?.taskDetails.source || isLoading
            ? [
                {
                  icon: <Icon icon={allImgPaths.globalSearchIcon} />,
                  label: 'Source Address',
                  value: (
                    <div className="flex flex-row gap-[6px] items-center text-white">
                      <div
                        className="cursor-pointer text-pink"
                        onClick={() => {
                          const redirectLink = getLinkFromNetwork(
                            `address/${taskDetailsData?.taskDetails.source}`,
                            taskDetailsData?.taskDetails.sourceNetwork
                          );
                          redirectLink && window.open(redirectLink, '_blank');
                        }}
                      >
                        <TruncateString text={taskDetailsData?.taskDetails.source as string} />
                      </div>
                      {taskDetailsData?.taskDetails.source && (
                        <CopyToClipboard
                          icon
                          content={taskDetailsData?.taskDetails.source}
                          id={taskDetailsData?.taskDetails.source}
                        />
                      )}
                    </div>
                  ),
                },
              ]
            : []),

          ...(taskDetailsData?.taskDetails.network || isLoading
            ? [
                {
                  icon: <Icon icon={allImgPaths.shardNetworkIcon} />,
                  label: 'Destination Network',
                  value: (
                    <div className="flex gap-1 text-white flex-wrap">
                      {taskDetailsData?.taskDetails.network ? (
                        <NetworkDetails data={taskDetailsData?.taskDetails.network} />
                      ) : (
                        <Skeleton.Loader className="h-4 w-10">-</Skeleton.Loader>
                      )}
                    </div>
                  ),
                },
              ]
            : []),

          ...(taskDetailsData?.taskDetails.destination || isLoading
            ? [
                {
                  icon: <Icon icon={allImgPaths.globalSearchIcon} />,
                  label: 'Destination Address',
                  value: (
                    <div className="flex flex-row gap-[6px] items-center text-white">
                      <div
                        className="cursor-pointer text-pink"
                        onClick={() => {
                          const redirectLink = getLinkFromNetwork(
                            `address/${taskDetailsData?.taskDetails.destination}`,
                            taskDetailsData?.taskDetails.network
                          );
                          redirectLink && window.open(redirectLink, '_blank');
                        }}
                      >
                        <TruncateString text={taskDetailsData?.taskDetails.destination as string} />
                      </div>
                      {taskDetailsData?.taskDetails.destination && (
                        <CopyToClipboard
                          icon
                          content={taskDetailsData?.taskDetails.destination}
                          id={taskDetailsData?.taskDetails.destination}
                        />
                      )}
                    </div>
                  ),
                },
              ]
            : []),

          ...(taskDetailsData?.taskDetails.destTxHash || isLoading
            ? [
                {
                  icon: <Icon icon={allImgPaths.hashTagIcon} />,
                  label: 'Destination Transaction Hash',
                  value: (
                    <div className="flex flex-row gap-[6px] items-center text-white">
                      <div
                        className="cursor-pointer text-pink"
                        onClick={() => {
                          const redirectLink = getLinkFromNetwork(
                            `tx/${taskDetailsData?.taskDetails.destTxHash}`,
                            taskDetailsData?.taskDetails.network
                          );
                          redirectLink && window.open(redirectLink, '_blank');
                        }}
                      >
                        <TruncateString text={taskDetailsData?.taskDetails.destTxHash as string} />
                      </div>
                      {taskDetailsData?.taskDetails.destTxHash && (
                        <CopyToClipboard
                          icon
                          content={taskDetailsData?.taskDetails.destTxHash}
                          id={taskDetailsData?.taskDetails.destTxHash}
                        />
                      )}
                    </div>
                  ),
                },
              ]
            : []),
        ]
      : [
          ...(taskDetailsData?.taskDetails.network || isLoading
            ? [
                {
                  icon: <Icon icon={allImgPaths.shardNetworkIcon} />,
                  label: 'Destination Network',
                  value: (
                    <div className="flex gap-1 text-white flex-wrap">
                      {taskDetailsData?.taskDetails.network ? (
                        <NetworkDetails data={taskDetailsData?.taskDetails.network} />
                      ) : (
                        <Skeleton.Loader className="h-4 w-10">-</Skeleton.Loader>
                      )}
                    </div>
                  ),
                },
              ]
            : []),
        ]),

    ...(taskDetailsData?.taskDetails.sig || isLoading
      ? [
          {
            icon: <Icon icon={allImgPaths.shardSignature} />,
            label: 'Signature',
            value: (
              <div className="flex flex-row gap-[6px] items-center text-white">
                <TruncateString
                  text={taskDetailsData?.taskDetails.sig as string}
                  textLength={width > 1440 ? 35 : undefined}
                />
                {taskDetailsData?.taskDetails.sig && (
                  <CopyToClipboard
                    icon
                    content={taskDetailsData?.taskDetails.sig}
                    id={taskDetailsData?.taskDetails.sig}
                  />
                )}
              </div>
            ),
          },
        ]
      : []),

    ...(taskDetailsData?.taskDetails.unAssignedReason || isLoading
      ? [
          {
            icon: <Icon icon={allImgPaths.unlinkIcon} />,
            label: 'Unassigned Reason',
            value: (
              <div className="text-white">
                {taskDetailsData?.taskDetails.unAssignedReason || '-'}
              </div>
            ),
          },
        ]
      : []),

    ...(taskDetailsData?.taskDetails.payload || isLoading
      ? [
          {
            icon: <Icon icon={allImgPaths.jsonIcon} />,
            label: 'Payload (JSON)',
            collapsible: true,
            value: (
              <div>
                {taskDetailsData?.taskDetails.payload && (
                  <JsonTable
                    index={taskDetailsData?.taskDetails.payload.toString() as string}
                    jsonData={taskDetailsData?.taskDetails.payload}
                  />
                )}
              </div>
            ),
          },
        ]
      : []),

    ...(taskDetailsData?.taskDetails.definition || isLoading
      ? [
          {
            icon: <Icon icon={allImgPaths.infoIcon} />,
            label: 'JSON Definition',
            collapsible: true,
            value: (
              <div>
                {taskDetailsData?.taskDetails.definition && (
                  <JsonTable
                    index={taskDetailsData?.taskDetails.definition.toString() as string}
                    jsonData={taskDetailsData?.taskDetails.definition}
                  />
                )}
              </div>
            ),
          },
        ]
      : []),
  ];
};
export default TaskData;

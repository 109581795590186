import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { createRoot } from 'react-dom/client';
// import { StrictMode } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import './index.css';

import App from './App';

const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_EXPLORER_SUBSCRIPTION_ENDPOINT,
  })
);

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_EXPLORER_GRAPHQL_ENDPOINT,
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

export const client = new ApolloClient({
  link: ApolloLink.from([new RetryLink(), splitLink]),
  cache: new InMemoryCache(),
});

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    // <StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
    // </StrictMode>
  );
}

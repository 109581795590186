import { useMemo } from 'react';

import { TelemetryQuery } from 'gql';

export type NetworkStatsType = {
  finalized_blocks: number;
  active_members: number;
  transfers: number;
  shards_live: number;
  tasks_created: number;
};

interface useTelemetryProps {
  supportedChain?: string[];
  telemetryData: TelemetryQuery | undefined;
}

const useTelemetry = ({ supportedChain, telemetryData }: useTelemetryProps) => {
  const networkStats = telemetryData?.telemetry as unknown as NetworkStatsType;
  const chains = useMemo(
    () => [...new Set(supportedChain?.map((el: string) => el.split(',')[0]))],
    [supportedChain]
  );
  return { networkStats, chains };
};
export default useTelemetry;

import DetailsCard from '../DetailsCard';

export const CopyrightPolicy = () => {
  return (
    <div className="">
      <h2 className="text-white text-2xl	md:text-[32px] md:leading-[40px] mb-3 md:mb-4">
        Copyright Policy
      </h2>
      <div className="flex flex-col gap-8 md:gap-10">
        <DetailsCard title="Intellectual Property Infringement">
          <p>
            We respect the intellectual property rights of others. It is Our policy to respond to
            any claim that Content posted on the Service infringes a copyright or other intellectual
            property infringement of any person.
          </p>
          <p>
            If You are a copyright owner, or authorized on behalf of one, and You believe that the
            copyrighted work has been copied in a way that constitutes copyright infringement that
            is taking place through the Service, You must submit Your notice in writing to the
            attention of our copyright agent via email at{' '}
            <a href="mailto:hr@analog.one" className="text-link cursor-pointer">
              hr@analog.one
            </a>{' '}
            and include in Your notice a detailed description of the alleged infringement.
          </p>
          <p>
            You may be held accountable for damages (including costs and attorneys' fees) for
            misrepresenting that any Content is infringing Your copyright.
          </p>
        </DetailsCard>

        <DetailsCard title="DMCA Notice and DMCA Procedure for Copyright Infringement Claims">
          <p>
            You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by
            providing our Copyright Agent with the following information in writing (see 17 U.S.C
            512(c)(3) for further detail):
          </p>
          <div className="pl-5">
            <ul className="list-disc flex flex-col gap-[6px]">
              <li>
                An electronic or physical signature of the person authorized to act on behalf of the
                owner of the copyright's interest.
              </li>
              <li>
                A description of the copyrighted work that You claim has been infringed, including
                the URL (i.e., web page address) of the location where the copyrighted work exists
                or a copy of the copyrighted work.
              </li>
              <li>
                Identification of the URL or other specific location on the Service where the
                material that You claim is infringing is located.
              </li>
              <li>Your address, telephone number, and email address.</li>
              <li>
                A statement by You that You have a good faith belief that the disputed use is not
                authorized by the copyright owner, its agent, or the law.
              </li>
              <li>
                A statement by You, made under penalty of perjury, that the above information in
                Your notice is accurate and that You are the copyright owner or authorized to act on
                the copyright owner's behalf.
              </li>
            </ul>
          </div>
          <p>
            You can contact our copyright agent via email at{' '}
            <a href="mailto:hr@analog.one" className="text-link cursor-pointer">
              hr@analog.one
            </a>
            {'. '} Upon receipt of a notification, the Company will take whatever action, in its
            sole discretion, it deems appropriate, including removal of the challenged content from
            the Service.
          </p>
        </DetailsCard>

        <DetailsCard title="Intellectual Property">
          <p>
            The Service and its original content (excluding Content provided by You or other users),
            features, and functionality are and will remain the exclusive property of the Company
            and its licensors.
          </p>
          <p>
            The Service is protected by copyright, trademark, and other laws of both the Country and
            foreign countries.
          </p>
          <p>
            Our trademarks and trade dress may not be used in connection with any product or service
            without the prior written consent of the Company.
          </p>
        </DetailsCard>

        <DetailsCard title="Your Feedback to Us">
          <p>
            You assign all rights, title, and interest in any Feedback You provide the Company. If
            for any reason such assignment is ineffective, You agree to grant the Company a
            non-exclusive, perpetual, irrevocable, royalty-free, worldwide right and license to use,
            reproduce, disclose, sub-license, distribute, modify, and exploit such Feedback without
            restriction.
          </p>
        </DetailsCard>

        <DetailsCard title="Links to Other Websites">
          <p>
            Our Service may contain links to third-party web sites or services that are not owned or
            controlled by the Company.
          </p>
          <p>
            The Company has no control over, and assumes no responsibility for, the content, privacy
            policies, or practices of any third-party web sites or services. You further acknowledge
            and agree that the Company shall not be responsible or liable, directly or indirectly,
            for any damage or loss caused or alleged to be caused by or in connection with the use
            of or reliance on any such content, goods, or services available on or through any such
            web sites or services.
          </p>
          <p>
            We strongly advise You to read the terms and conditions and privacy policies of any
            third-party web sites or services that You visit.
          </p>
        </DetailsCard>
        <DetailsCard title="Termination">
          <p>
            We may terminate or suspend Your Account immediately, without prior notice or liability,
            for any reason whatsoever, including without limitation if You breach these Terms and
            Conditions.
          </p>
          <p>
            Upon termination, Your right to use the Service will cease immediately. If You wish to
            terminate Your Account, You may simply discontinue using the Service.
          </p>
        </DetailsCard>

        <DetailsCard title="Limitation of Liability">
          <p>
            Not with standing any damages that You might incur, the entire liability of the Company
            and any of its suppliers under any provision of these Terms and Your exclusive remedy
            for all of the foregoing shall be limited to the amount actually paid by You through the
            Service or 100 USD if You haven't purchased anything through the Service.
          </p>
          <p>
            To the maximum extent permitted by applicable law, in no event shall the Company or its
            suppliers be liable for any special, incidental, indirect, or consequential damages
            whatsoever (including, but not limited to, damages for loss of profits, loss of data or
            other information, for business interruption, for personal injury, loss of privacy
            arising out of or in any way related to the use of or inability to use the Service,
            third-party software and/or third-party hardware used with the Service, or otherwise in
            connection with any provision of these Terms), even if the Company or any supplier has
            been advised of the possibility of such damages and even if the remedy fails of its
            essential purpose.
          </p>
          <p>
            Some jurisdictions do not allow the exclusion of implied warranties or limitation of
            liability for incidental or consequential damages, which means that some of the above
            limitations may not apply. In these jurisdictions, each party's liability will be
            limited to the greatest extent permitted by law.
          </p>
        </DetailsCard>

        <DetailsCard title={'"AS IS" and "AS AVAILABLE" Disclaimer'}>
          <p>
            The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and
            defects without warranty of any kind. To the maximum extent permitted under applicable
            law, the Company, on its own behalf and on behalf of its Affiliates and its and their
            respective licensors and service providers, expressly disclaims all warranties, whether
            express, implied, statutory or otherwise, with respect to the Service, including all
            implied warranties of merchantability, fitness for a particular purpose, title and
            non-infringement, and warranties that may arise out of course of dealing, course of
            performance, usage or trade practice. Without limitation to the foregoing, the Company
            provides no warranty or undertaking, and makes no representation of any kind that the
            Service will meet Your requirements, achieve any intended results, be compatible or work
            with any other software, applications, systems or services, operate without
            interruption, meet any performance or reliability standards or be error-free or that any
            errors or defects can or will be corrected.
          </p>
          <p>
            Without limiting the foregoing, neither the Company nor any of the company's provider
            makes any representation or warranty of any kind, express or implied:
          </p>
          <div className="pl-5">
            <ul className="list-disc flex flex-col gap-[6px]">
              <li>
                as to the operation or availability of the Service, or the information, content, and
                materials or products included thereon;
              </li>
              <li>that the Service will be uninterrupted or error-free;</li>
              <li>
                as to the accuracy, reliability, or currency of any information or content provided
                through the Service;
              </li>
              <li>
                that the Service, its servers, the content, or e-mails sent from or on behalf of the
                Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or
                other harmful components.
              </li>
            </ul>
          </div>
          <p>
            Some jurisdictions do not allow the exclusion of certain types of warranties or
            limitations on applicable statutory rights of a consumer, so some or all of the above
            exclusions and limitations may not apply to You. But in such a case the exclusions and
            limitations set forth in this section shall be applied to the greatest extent
            enforceable under applicable law.
          </p>
        </DetailsCard>

        <DetailsCard title="For European Union (EU) Users">
          <p>
            If You are a European Union consumer, you will benefit from any mandatory provisions of
            the law of the country in which You are resident.
          </p>
        </DetailsCard>

        <DetailsCard title="United States Federal Government End Use Provisions">
          <p>
            If You are a U.S. federal government end user, our Service is a "Commercial Item" as
            that term is defined at 48 C.F.R. §2.101.
          </p>
        </DetailsCard>

        <DetailsCard title="United States Legal Compliance">
          <p>
            You represent and warrant that (i) You are not located in a country that is subject to
            the United States government embargo, or that has been designated by the United States
            government as a "terrorist supporting" country, and (ii) You are not listed on any
            United States government list of prohibited or restricted parties.
          </p>
        </DetailsCard>
      </div>
    </div>
  );
};

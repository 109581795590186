export const FacebookFan = () => {
  return (
    <div>
      <h2 className="text-white text-2xl	md:text-[32px] md:leading-[40px] mb-3 md:mb-4">
        Facebook Fan Page
      </h2>

      <div className="flex flex-col gap-8 md:gap-10 mb-8 md:mb-10">
        <div>
          <h3 className="text-xl md:text-2xl text-white mb-[2px] md:mb-[10px]">
            Data Controller for the Facebook Fan Page
          </h3>
          <div className="flex flex-col md:gap-4 gap-[6px]">
            <p>
              The Company is the Data Controller of Your Personal Data collected while using the
              Service. As operator of the Facebook Fan Page{' '}
              <a href="https://www.facebook.com/analogpost/" className="text-link cursor-pointer">
                https://www.facebook.com/analogpost/{' '}
              </a>
              the Company and the operator of the social network Facebook are Joint Controllers.
            </p>
            <p>
              The Company has entered into agreements with Facebook that define the terms for use of
              the Facebook Fan Page, among other things. These terms are mostly based on the
              Facebook Terms of Service:{' '}
              <a href="https://www.facebook.com/terms.php" className="text-link cursor-pointer">
                https://www.facebook.com/terms.php
              </a>
            </p>
            <p>
              Visit the Facebook Privacy Policy{' '}
              <a href="https://www.facebook.com/terms.php" className="text-link cursor-pointer">
                https://www.facebook.com/policy.php
              </a>{' '}
              for more information about how Facebook manages Personal data or contact Facebook
              online, or by mail: Facebook, Inc. ATTN, Privacy Operations, 1601 Willow Road, Menlo
              Park, CA 94025, United States.
            </p>
          </div>
        </div>

        <div>
          <h3 className="text-xl md:text-2xl text-white mb-[2px] md:mb-[10px]">
            Facebook Insights
          </h3>
          <div className="flex flex-col md:gap-4 gap-[6px]">
            <p>
              We use the Facebook Insights function in connection with the operation of the Facebook
              Fan Page and on the basis of the GDPR, in order to obtain anonymized statistical data
              about Our users.
            </p>
            <p>
              For this purpose, Facebook places a Cookie on the device of the user visiting Our
              Facebook Fan Page. Each Cookie contains a unique identifier code and remains active
              for a period of two years, except when it is deleted before the end of this period.
            </p>
            <p>
              Facebook receives, records and processes the information stored in the Cookie,
              especially when the user visits the Facebook services, services that are provided by
              other members of the Facebook Fan Page and services by other companies that use
              Facebook services.
            </p>
            <p>
              For more information on the privacy practices of Facebook, please visit Facebook
              Privacy Policy here:{' '}
              <a
                href="https://www.facebook.com/privacy/explanation"
                className="text-link cursor-pointer"
              >
                https://www.facebook.com/privacy/explanation
              </a>
            </p>
          </div>
        </div>

        <div>
          <h3 className="text-xl md:text-2xl text-white mb-[2px] md:mb-[10px]">
            "Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)
          </h3>
          <div className="flex flex-col md:gap-4 gap-[6px]">
            <p>Our Service does not respond to Do Not Track signals.</p>
            <p>
              However, some third party websites do keep track of Your browsing activities. If You
              are visiting such websites, You can set Your preferences in Your web browser to inform
              websites that You do not want to be tracked. You can enable or disable DNT by visiting
              the preferences or settings page of Your web browser.
            </p>
          </div>
        </div>

        <div>
          <h3 className="text-xl md:text-2xl text-white mb-[2px] md:mb-[10px]">
            Children's Privacy
          </h3>
          <div className="flex flex-col md:gap-4 gap-[6px]">
            <p>
              Our Service does not address anyone under the age of 13. We do not knowingly collect
              personally identifiable information from anyone under the age of 13. If You are a
              parent or guardian and You are aware that Your child has provided Us with Personal
              Data, please contact Us. If We become aware that We have collected Personal Data from
              anyone under the age of 13 without verification of parental consent, We take steps to
              remove that information from Our servers.
            </p>
            <p>
              If We need to rely on consent as a legal basis for processing Your information and
              Your country requires consent from a parent, We may require Your parent's consent
              before We collect and use that information.
            </p>
          </div>
        </div>

        <div>
          <h3 className="text-xl md:text-2xl text-white mb-[2px] md:mb-[10px]">
            Links to Other Websites
          </h3>
          <div className="flex flex-col md:gap-4 gap-[6px]">
            <p>
              Our Service may contain links to other websites that are not operated by Us. If You
              click on a third party link, You will be directed to that third party's site. We
              strongly advise You to review the Privacy Policy of every site You visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the content, privacy policies
              or practices of any third party sites or services.
            </p>
          </div>
        </div>

        <div>
          <h3 className="text-xl md:text-2xl text-white mb-[2px] md:mb-[10px]">
            Changes to this Privacy Policy
          </h3>
          <div className="flex flex-col md:gap-4 gap-[6px]">
            <p>
              We may update Our Privacy Policy from time to time. We will notify You of any changes
              by posting the new Privacy Policy on this page.
            </p>
            <p>
              We will let You know via email and/or a prominent notice on Our Service, prior to the
              change becoming effective and update the "Last updated" date at the top of this
              Privacy Policy.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any changes. Changes to
              this Privacy Policy are effective when they are posted on this page.
            </p>
          </div>
        </div>

        <div>
          <h3 className="text-xl md:text-2xl text-white mb-[2px] md:mb-[10px]">Contact Us</h3>
          <div className="flex flex-col md:gap-4 gap-[6px]">
            <p>
              If you have any questions about this Privacy Policy, You can contact us via email at
              <a href="mailto:legal@analog.one" className="text-link cursor-pointer">
                {' '}
                legal@analog.one
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacebookFan;

export const DEFINITIONS = [
  {
    title: 'Account',
    description:
      'means a unique account created for You to access our Service or parts of our Service.',
  },
  {
    title: 'Affiliate',
    description:
      'means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.',
  },
  {
    title: 'Company',
    description:
      '(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Analog Time Corporation Limited, a Seychelles Business Corporation, with a mailing address of c/o Silk Legal Co., Ltd. RSU Tower, Suite 805 571 Sukhumvit Road Watthana, Bangkok Thailand, 10110. For the purpose of the GDPR, the Company is the Data Controller.',
  },
  {
    title: 'Cookies',
    description:
      'are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.',
  },
  {
    title: 'Country',
    description: 'refers to: Seychelles',
  },
  {
    title: 'Data Controller',
    description:
      'for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.',
  },
  {
    title: 'Device',
    description:
      'means any device that can access the Service such as a computer, a cell phone or a digital tablet.',
  },
  {
    title: 'Do Not Track (DNT)',
    description:
      'is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.',
  },
  {
    title: 'Facebook Fan Page',
    description: (
      <>
        is a public profile named Analog specifically created by the Company on the Facebook social
        network, accessible from{' '}
        <a href="https://www.facebook.com/analogpost/" className="text-link cursor-pointer">
          https://www.facebook.com/analogpost/
        </a>
      </>
    ),
  },
  {
    title: 'GDPR',
    description: 'refers to EU General Data Protection Regulation.',
  },
  {
    title: 'Personal Data',
    description:
      'is any information that relates to an identified or identifiable individual. For the purposes of GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.',
  },
  {
    title: 'Service',
    description:
      'refers to the Website and the Company’s products, programs, and applications known as Analog Watch (including Watch Portal and Watch SDK), Analog Incentivized Testnet, and Analog General Message Passing.',
  },
  {
    title: 'Service Provider',
    description:
      'means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR, Service Providers are considered Data Processors.',
  },
  {
    title: 'Third-party Social Media Service',
    description:
      'refers to any website or any social network website through which a User can log in or create an account to use the Service.',
  },
  {
    title: 'Usage Data',
    description:
      'refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).',
  },
  {
    title: 'Website',
    description: (
      <>
        refers to Analog, accessible from{' '}
        <a href="https://www.analog.one" className="text-link cursor-pointer">
          https://www.analog.one
        </a>
      </>
    ),
  },
  {
    title: 'You',
    description:
      'means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
  },
  {
    title: '',
    description:
      'Under GDPR, You can be referred to as the Data Subject or as the User as you are the individual using the Service.',
  },
];

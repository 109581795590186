import { HTMLAttributes } from 'react';

import { CopyToClipboard } from '@analog/ui/src';
import { Text, TruncatedText } from 'components';
import { Skeleton } from 'components/Skeleton';
import { classNames, classes } from 'utils';

export interface TableColumnsProps extends HTMLAttributes<HTMLDivElement> {
  classNames?: classNames<
    'content' | 'container' | 'beforeIcon' | 'skeletonIcon' | 'skeletonLabel' | 'skeletonText'
  >;
  value: string;
  withCopyButton?: boolean;
  colIndex?: string;
  dataTooltipContent?: string;
  dataTooltipId?: string;
  textColor?: string;
  beforeImagePath?: string;
  label?: string;
  onClick?: () => void;
  copyValue?: string;
  withTruncate?: boolean;
  width?: string;
}

function TableColumns({
  classNames,
  value,
  colIndex,
  dataTooltipContent,
  dataTooltipId,
  textColor,
  beforeImagePath,
  label,
  withCopyButton = false,
  onClick,
  copyValue,
  withTruncate = false,
  width,
  ...rest
}: TableColumnsProps) {
  return (
    <div className={classes('flex items-center', classNames?.base)} {...rest}>
      <div className={classes('flex gap-[6px] items-center w-[90%]', classNames?.container)}>
        {beforeImagePath && (
          <Skeleton.Loader className="h-6 w-6 !rounded-full" containerClassName="h-6">
            <img
              src={beforeImagePath}
              className={classes('h-6 w-6', classNames?.beforeIcon, classNames?.skeletonIcon)}
            />{' '}
          </Skeleton.Loader>
        )}

        <div className={classes('flex w-max', withTruncate && 'w-full', label && 'flex-col')}>
          {label && (
            <Skeleton.Loader
              className={classes('h-4 w-15 !rounded-full', classNames?.skeletonLabel)}
              containerClassName="h-4 mb-1"
            >
              <Text extraClass="text-xs">{label}</Text>
            </Skeleton.Loader>
          )}
          <Skeleton.Loader
            className={classes(
              'h-4 w-24 !rounded-full',
              withCopyButton && 'w-full',
              classNames?.skeletonText
            )}
            containerClassName={classes('h-4 flex', withCopyButton && 'w-full')}
          >
            {withTruncate && value ? (
              <TruncatedText
                data-tooltip-id={dataTooltipId}
                data-tooltip-content={dataTooltipContent}
                originalText={value}
                textColor={textColor}
                withCopyButton={withCopyButton}
                width={width || '90%'}
                className={classNames?.content}
                onClick={() => onClick && onClick()}
              />
            ) : (
              <Text
                data-tooltip-id={dataTooltipId}
                data-tooltip-content={dataTooltipContent}
                color={textColor}
                extraClass={classes('truncate', classNames?.content)}
                onClick={() => onClick && onClick()}
              >
                {value}
              </Text>
            )}
          </Skeleton.Loader>
        </div>
      </div>
      {withCopyButton && !withTruncate && value && value != '-' && (
        <Skeleton.Loader className="h-4 w-4 !rounded-full" containerClassName="h-4">
          <CopyToClipboard icon content={copyValue || value} id={value + colIndex} />
        </Skeleton.Loader>
      )}
    </div>
  );
}

export default TableColumns;

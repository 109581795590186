import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '../../utils/classnames';

const IconButtonVariants = cva(
  'rounded-full text-sm font-medium focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 cursor-pointer',
  {
    variants: {
      variant: {
        default: 'bg-transparent text-white hover:bg-[#ffffff1a] border border-white',
        ghost: 'bg-transparent text-white hover:bg-[#ffffff1a]',
        outline: 'bg-transparent text-white hover:bg-[#ffffff1a] border border-white',
        filled: 'bg-[#ffffff1a] text-black',
      },
      size: {
        default: 'p-[13px]',
        big: 'p-[13px]',
        medium: 'p-[9px]',
        small: 'p-[5px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof IconButtonVariants> {
  asChild?: boolean;
  icon: any;
  iconClasses?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      className,
      icon,
      iconClasses,
      variant,
      size,
      disabled,
      onClick = () => {},
      asChild = false,
      ...props
    },
    ref
  ) => {
    const Button = asChild ? Slot : 'button';
    return (
      <Button
        className={cn(
          'flex justify-center items-center',
          IconButtonVariants({ variant, size, className })
        )}
        ref={ref}
        disabled={disabled}
        onClick={onClick}
        {...props}
      >
        <img src={icon} className={cn({ size }, iconClasses)} alt="icon" />
      </Button>
    );
  }
);
IconButton.displayName = 'IconButton';

export { IconButton, IconButtonVariants };

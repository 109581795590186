import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '../../utils/classnames';

const buttonVariants = cva(
  'rounded-full text-sm font-medium focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-transparent text-white hover:bg-[#ffffff1a] border border-white',
        ghost: 'bg-transparent text-white hover:bg-[#ffffff1a]',
        outline: 'bg-transparent text-white hover:bg-[#ffffff1a] border border-white',
        filled: 'bg-white text-black  hover:bg-[#ffffffb3]',
      },
      size: {
        default: 'px-[27px] py-3 ',
        big: 'px-[27px] py-3',
        medium: 'px-[26px] py-2.5',
        small: 'px-[26px] py-[7px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Button = asChild ? Slot : 'button';
    return (
      <Button className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };

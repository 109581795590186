export const DEFINITIONS = [
  {
    title: 'Affiliate: ',
    desc: 'An entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.',
  },
  {
    title: 'Account: ',
    desc: 'A unique account created for You to access our Service or parts of our Service.',
  },
  {
    title: 'Country: ',
    desc: 'Refers to the Republic of Seychelles.',
  },
  {
    title: 'Company: ',
    desc: 'Refers to Analog Time Corporation Limited, located at [Seychelles Address].',
  },
  {
    title: 'Content: ',
    desc: 'Content such as text, images, or other information that can be posted, uploaded, linked to, or otherwise made available by You, regardless of the form of that content.',
  },
  {
    title: 'Device: ',
    desc: 'Any device that can access the Service such as a computer, a cell phone, or a digital tablet.',
  },
  {
    title: 'Feedback: ',
    desc: 'Feedback, innovations, or suggestions sent by You regarding the attributes, performance, or features of our Service.',
  },
  {
    title: 'Orders: ',
    desc: 'A request by You to access services or features provided by the Service.',
  },
  {
    title: 'Promotions: ',
    desc: 'Contests, sweepstakes, or other promotions offered through the Service.',
  },
  {
    title: 'Service: ',
    desc: 'Refers to the Explorer platform and the Website.',
  },
  {
    title: 'Terms and Conditions (Terms): ',
    desc: 'These Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.',
  },
  {
    title: 'Third-party Social Media Service: ',
    desc: 'Any services or content (including data, information, products, or services) provided by a third-party that may be displayed, included, or made available by the Service.',
  },
  {
    title: 'Website: ',
    desc: (
      <>
        Refers to Explorer, accessible from{' '}
        <a href="https://www.analog.one/" className="text-link cursor-pointer">
          https://www.analog.one/
        </a>
      </>
    ),
  },
  {
    title: 'You: ',
    desc: 'The individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
  },
];

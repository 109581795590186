import { Icon, NetworkDetails } from '@analog/ui';
import allImgPaths from 'assets/allImgPaths';
import JsonTable from 'components/common/JsonTable';
import { Skeleton } from 'components/Skeleton';
import { ShardDetailsQuery } from 'gql';
import moment from 'moment';
import { getIcon, parseStringToArray, timeAgo } from 'utils';
interface ShardDataProps {
  data: ShardDetailsQuery | undefined;
}

const ShardData = ({ data }: ShardDataProps) => {
  return [
    // Not consistent text colour in table.
    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Shard ID',
      value: <div className="text-pink">{data?.shardDetails?.id}</div>,
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Timestamp',
      value: data?.shardDetails?.created_at ? (
        <div className="text-white">
          {`${timeAgo(new Date(data?.shardDetails?.created_at))} (${moment
            .utc(data?.shardDetails?.created_at)
            .format('MMM-D-YYYY h:mm:ss A +UTC')})`}
        </div>
      ) : (
        '-'
      ),
    },
    {
      icon: <Icon icon={allImgPaths.shardSignature} />,
      label: 'Signature',
      collapsible: true,
      value: (
        <JsonTable
          index={data?.shardDetails?.id.toString() as string}
          jsonData={parseStringToArray(data?.shardDetails?.sig?.split(','))}
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.taskStatus} />,
      label: 'Shard Status',
      value: (
        <div className="text-stone-50 text-sm font-normal leading-snug flex items-center gap-2">
          <img src={getIcon(data?.shardDetails?.state.toLowerCase() as string)} alt="" />
          {data?.shardDetails?.state}
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.hashTagIcon} />,
      label: 'Commitment',
      collapsible: true,
      value: (
        <JsonTable
          index={data?.shardDetails?.id.toString() as string}
          jsonData={parseStringToArray(data?.shardDetails?.commitment)}
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.shardNetworkIcon} />,
      label: 'Network',
      value: (
        <div className="flex gap-1 flex-wrap">
          {data?.shardDetails?.network ? (
            <NetworkDetails data={data?.shardDetails?.network} />
          ) : (
            <Skeleton.Loader className="h-4 w-10">-</Skeleton.Loader>
          )}
        </div>
      ),
    },
  ];
};
export default ShardData;

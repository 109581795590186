import { CopyToClipboard, HashIcon, Icon, NetworkDetails, TruncateString } from '@analog/ui';
import allImgPaths from 'assets/allImgPaths';
import { Skeleton } from 'components/Skeleton';
import { MemberDetailsQuery } from 'gql';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { getIcon, timeAgo } from 'utils';

interface ChronicleDataProps {
  chroniclesData: MemberDetailsQuery | undefined;
}

const ChronicleData = ({ chroniclesData }: ChronicleDataProps) => {
  return [
    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Chronicle address',
      value: (
        <HashIcon
          value={chroniclesData?.memberDetails?.account_id}
          className="text-white"
          copyable
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Timestamp',
      value: chroniclesData?.memberDetails?.created_at
        ? `${timeAgo(new Date(chroniclesData?.memberDetails?.created_at))} (${moment
            .utc(chroniclesData?.memberDetails?.created_at)
            .format('MMM-D-YYYY h:mm:ss A +UTC')})`
        : '-',
    },
    {
      icon: <Icon icon={allImgPaths.shardNetworkIcon} />,
      label: 'Network',
      value: (
        <div className="flex gap-1 flex-wrap">
          {chroniclesData?.memberDetails?.network ? (
            <NetworkDetails data={chroniclesData?.memberDetails?.network} />
          ) : (
            <Skeleton.Loader className="h-4 w-10">-</Skeleton.Loader>
          )}
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.shardSignature} />,
      label: 'Signature',
      value: (
        <>
          <TruncateString text={chroniclesData?.memberDetails.sig as string} />
          {chroniclesData?.memberDetails.sig && (
            <CopyToClipboard
              icon
              content={chroniclesData?.memberDetails.sig}
              id={chroniclesData?.memberDetails.sig}
            />
          )}
        </>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.tickCircleIcon} />,
      label: 'State',
      value: (
        <div className="text-sm font-normal leading-snug flex items-center gap-2 capitalize">
          <img src={getIcon(chroniclesData?.memberDetails.state?.toLowerCase() as string)} alt="" />
          {chroniclesData?.memberDetails.state}
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.hashTagIcon} />,
      label: 'Shard ID',
      value: chroniclesData?.memberDetails.shardId && (
        <Link
          className="flex flex-row gap-[6px] items-center cursor-pointer text-pink"
          to={`${routes.shard}/${chroniclesData?.memberDetails.shardId}`}
        >
          {chroniclesData?.memberDetails.shardId}
        </Link>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.heartIcon} />,
      label: 'Heartbeat',
      value: (
        <div className="flex flex-row gap-[6px] items-center">
          {chroniclesData?.memberDetails.heartbeat}
        </div>
      ),
    },
  ];
};

export default ChronicleData;

import { useApiAccess } from 'contexts/AccessProvider';
import { useSupportedChainsQuery, useTelemetryQuery } from 'gql';

export const useTelemetryData = () => {
  const { sessionKey } = useApiAccess();

  const { data: telemetryData, loading: telemetryLoading } = useTelemetryQuery({
    variables: { sessionKey },
    fetchPolicy: 'cache-and-network',
  });

  const { data: supportedChain, loading: supportedChainLoading } = useSupportedChainsQuery({
    variables: { sessionKey },
    fetchPolicy: 'cache-and-network',
  });

  return { supportedChain, supportedChainLoading, telemetryData, telemetryLoading };
};

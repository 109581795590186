import { DetailsListNavigation, HashIcon, Icon } from '@analog/ui';
import allImgPaths from 'assets/allImgPaths';
import { Text, TruncatedText } from 'components';
import JsonTable from 'components/common/JsonTable';
import { FontColors } from 'consts';
import { ExtrinsicDetailsQuery } from 'gql';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { formatAmount, timeAgo } from 'utils';
interface OverviewDetailsProps {
  extrinsicDetailsData: ExtrinsicDetailsQuery | undefined;
}
const OverviewDetails = ({ extrinsicDetailsData }: OverviewDetailsProps) => {
  const navigate = useNavigate();
  return [
    {
      icon: <Icon icon={allImgPaths.dataArrowsIcon} />,
      label: 'Extrinsic',
      value: (
        <div className="flex items-center gap-2">
          {extrinsicDetailsData?.extrinsicDetails?.id}
          <DetailsListNavigation
            handlePrevDetails={() => {
              if (extrinsicDetailsData?.extrinsicDetails?.previousExtrinsic)
                navigate(
                  `${routes.extrinsic}/${extrinsicDetailsData?.extrinsicDetails?.previousExtrinsic}`
                );
            }}
            disablePrev={!extrinsicDetailsData?.extrinsicDetails?.previousExtrinsic}
            handleNextDetails={() => {
              if (extrinsicDetailsData?.extrinsicDetails?.nextExtrinsic)
                navigate(
                  `${routes.extrinsic}/${extrinsicDetailsData?.extrinsicDetails?.nextExtrinsic}`
                );
            }}
            disableNext={!extrinsicDetailsData?.extrinsicDetails?.nextExtrinsic}
          />
        </div>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.hashTagOutlineIcon} />,
      label: 'Block Number',
      value: (
        <Text
          color={FontColors.PURPLE}
          extraClass="cursor-pointer"
          onClick={() =>
            navigate(`${routes.block}/${extrinsicDetailsData?.extrinsicDetails?.block_number}`)
          }
        >
          {extrinsicDetailsData?.extrinsicDetails?.block_number}
        </Text>
      ),
    },
    {
      icon: <Icon icon={allImgPaths.clockIcon} />,
      label: 'Timestamp',
      value: extrinsicDetailsData?.extrinsicDetails?.created_at
        ? `${timeAgo(new Date(extrinsicDetailsData?.extrinsicDetails?.created_at))} (${moment
            .utc(extrinsicDetailsData?.extrinsicDetails?.created_at)
            .format('MMM-D-YYYY h:mm:ss A +UTC')})`
        : '-',
    },
    {
      icon: <Icon icon={allImgPaths.heart2Icon} />,
      label: 'Life Time',
      collapsible: true,
      value: (
        <JsonTable
          index={extrinsicDetailsData?.extrinsicDetails?.id.toString() as string}
          jsonData={extrinsicDetailsData?.extrinsicDetails?.life_time}
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.hashTagIcon} />,
      label: 'Extrinsic Hash',
      value: (
        <TruncatedText
          originalText={extrinsicDetailsData?.extrinsicDetails?.hash as string}
          withCopyButton
          width="80%"
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.senderIcon} />,
      label: 'Sender/Signer',
      value: (
        <HashIcon
          value={extrinsicDetailsData?.extrinsicDetails?.signer}
          className="text-white"
          copyable
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.transferIcon} />,
      label: 'Assets Transfer',
      value: formatAmount(extrinsicDetailsData?.extrinsicDetails?.assets_transfer || '0'),
    },
    {
      icon: <Icon icon={allImgPaths.mathIcon} />,
      label: 'Estimated Fee',
      value: formatAmount(extrinsicDetailsData?.extrinsicDetails?.estimated_fee || '0'),
    },
    {
      icon: <Icon icon={allImgPaths.walletIcon} />,
      label: 'Used fee',
      value: formatAmount(extrinsicDetailsData?.extrinsicDetails?.used_fee || '0'),
    },
    {
      icon: <Icon icon={allImgPaths.clipboardCloseIcon} />,
      label: 'Nonce',
      value: extrinsicDetailsData?.extrinsicDetails?.nonce,
    },
    {
      icon: <Icon icon={allImgPaths.clipboardTickIcon} />,
      label: 'Result',
      value: extrinsicDetailsData?.extrinsicDetails?.result,
    },
    {
      icon: <Icon icon={allImgPaths.parametersIcon} />,
      label: 'Parameters',
      collapsible: true,
      value: (
        <JsonTable
          index={extrinsicDetailsData?.extrinsicDetails?.id.toString() as string}
          jsonData={extrinsicDetailsData?.extrinsicDetails?.args}
        />
      ),
    },
    {
      icon: <Icon icon={allImgPaths.graphIcon} />,
      label: 'Signature',
      value: (
        <TruncatedText
          originalText={extrinsicDetailsData?.extrinsicDetails?.signature || ''}
          width="80%"
        />
      ),
    },
  ];
};

export default OverviewDetails;

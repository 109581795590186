import Layout from 'components/layout';
import {
  AccountDetails,
  Accounts,
  BlockDetails,
  Blocks,
  Chronicle,
  ChronicleDetails,
  Events,
  ExtrinsicDetails,
  Extrinsics,
  Faq,
  GMP,
  Home,
  PageNotFound,
  PrivacyPolicy,
  ShardDetails,
  Shards,
  TaskDetails,
  TermsAndConditions,
  ValidatorDetails,
  Validators,
} from 'pages';
import { Navigate, createHashRouter } from 'react-router-dom';

import { detailsRoutes, routes } from './routesConst';

const routerConfig = [
  {
    element: <Layout />,
    children: [
      {
        path: routes.base,
        element: <Home />,
      },
      {
        path: routes.blocks,
        element: <Blocks />,
      },
      {
        path: detailsRoutes.blockDetails,
        element: <BlockDetails />,
      },
      {
        path: `${routes.events}/${routes.extrinsicsId}`,
        element: <Events />,
      },
      {
        path: routes.extrinsics,
        element: <Extrinsics />,
      },
      {
        path: detailsRoutes.extrinsicsDetails,
        element: <ExtrinsicDetails />,
      },
      {
        path: routes.chronicles,
        element: <Chronicle />,
      },
      {
        path: detailsRoutes.chroniclesDetails,
        element: <ChronicleDetails />,
      },
      {
        path: detailsRoutes.gmpDetails,
        element: <TaskDetails />,
      },
      {
        path: routes.validators,
        element: <Validators />,
      },
      {
        path: detailsRoutes.validatorDetails,
        element: <ValidatorDetails />,
      },
      {
        path: routes.shards,
        element: <Shards />,
      },
      {
        path: detailsRoutes.shardsDetails,
        element: <ShardDetails />,
      },
      {
        path: routes.faqs,
        element: <Faq />,
      },
      {
        path: routes.gmp,
        element: <GMP />,
      },
      {
        path: routes.accounts,
        element: <Accounts />,
      },
      {
        path: detailsRoutes.accountDetails,
        element: <AccountDetails />,
      },
      {
        path: routes.privacyPolicy,
        element: <PrivacyPolicy />,
      },
      {
        path: routes.termsAndConditions,
        element: <TermsAndConditions />,
      },
      {
        path: routes[404],
        element: <PageNotFound />,
      },
    ],
  },

  {
    path: '/*',
    element: <Navigate to={routes[404]} replace />,
  },
];

export const router = createHashRouter(routerConfig);

import { DetailsList } from '@analog/ui';
import { Heading, SearchBox } from 'components';

import useChroniclesDetails from './useChroniclesDetails';

export function ChronicleDetails() {
  const { chronicleData, isLoading, id } = useChroniclesDetails();

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        <Heading
          className="text-white font-medium leading-none truncate w-full"
          title={`Chronicle`}
          subTitle={`#${id}`}
          classNames={{
            base: 'flex md:gap-[8px] leading-[unset]',
            title: 'truncate',
            subtitle: 'md:text-[36px] text-[18px] break-all whitespace-pre-line',
          }}
          isLoading={isLoading}
        />
        <SearchBox defaultSearchType={'Chronicles'} />
      </div>
      <div className="md:mt-[40px] mt-[32px]">
        <DetailsList title={'Chronicle Info'} details={chronicleData} isLoading={isLoading} />
      </div>
    </>
  );
}

export default ChronicleDetails;

import DetailsCard from '../DetailsCard';

import { DEFINITIONS } from './utils';

export const InterpretationDefinition = () => {
  return (
    <div className="">
      <h2 className="text-white text-2xl	md:text-[32px] md:leading-[40px] mb-3 md:mb-4">
        Interpretation and Definitions
      </h2>
      <div className="flex flex-col gap-8 md:gap-10">
        <DetailsCard title="Interpretation">
          <p>
            The words of which the initial letter is capitalized have meanings defined under the
            following conditions. The following definitions shall have the same meaning regardless
            of whether they appear in singular or in plural.
          </p>
        </DetailsCard>

        <DetailsCard title="Definitions">
          <p>For the purposes of these Terms and Conditions:</p>
          <ul className="list-disc flex flex-col gap-2 pl-6 md:pl-8">
            {DEFINITIONS.map(({ title, desc }) => {
              return (
                <li className="text-white">
                  {title} <span className="text-offWhite">{desc}</span>
                </li>
              );
            })}
          </ul>
        </DetailsCard>

        <DetailsCard title="Acknowledgment">
          <p>
            These are the Terms and Conditions governing the use of this Service and the agreement
            that operates between You and the Company. These Terms and Conditions set out the rights
            and obligations of all users regarding the use of the Service.
          </p>
          <p>
            Your access to and use of the Service is conditioned on Your acceptance of and
            compliance with these Terms and Conditions. These Terms and Conditions apply to all
            visitors, users, and others who access or use the Service.
          </p>
          <p>
            By accessing or using the Service You agree to be bound by these Terms and Conditions.
            If You disagree with any part of these Terms and Conditions then You may not access the
            Service.
          </p>
          <p>
            You represent that you are over the age of 18. The Company does not permit those under
            18 to use the Service.
          </p>
          <p>
            Your access to and use of the Service is also conditioned on Your acceptance of and
            compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our
            policies and procedures on the collection, use, and disclosure of Your personal
            information when You use the Application or the Website and tells You about Your privacy
            rights and how the law protects You. Please read Our Privacy Policy carefully before
            using Our Service.
          </p>
        </DetailsCard>

        <DetailsCard title="Placing Orders for Services">
          <p>
            By placing an Order for services through the Service, You warrant that You are legally
            capable of entering into binding contracts.
          </p>
        </DetailsCard>

        <DetailsCard title="Your Information">
          <p>
            If You wish to place an Order for services available on the Service, You may be asked to
            supply certain information relevant to Your Order, including, without limitation, Your
            name, Your email, Your address, and Your payment method.
          </p>
          <p>
            You represent and warrant that: (i) You have the legal right to use the payment
            method(s) in connection with any Order; and that (ii) the information You supply to us
            is true, correct, and complete.
          </p>
          <p>
            By submitting such information, You grant us the right to provide the information to
            payment gateways and payment processing third parties for purposes of facilitating the
            completion of Your Order.
          </p>
        </DetailsCard>

        <DetailsCard title="Order Cancellation">
          <p>
            We reserve the right to refuse or cancel Your Order at any time for certain reasons
            including but not limited to:
          </p>
          <div className="pl-5">
            <ul className="list-disc">
              <li>Service availability</li>
              <li>Errors in the description or prices for services</li>
              <li>Errors in Your Order</li>
            </ul>
          </div>
          <p>
            We reserve the right to refuse or cancel Your Order if fraud or an unauthorized or
            illegal transaction is suspected.
          </p>
        </DetailsCard>

        <DetailsCard title="Your Order Cancellation Rights">
          <p>Any services you purchase are final and cannot be canceled, reversed, or returned.</p>
        </DetailsCard>

        <DetailsCard title="Availability, Errors, and Inaccuracies">
          <p>
            We are constantly updating Our offerings of services on the Service. The services
            available on Our Service may be mispriced, described inaccurately, or unavailable, and
            we may experience delays in updating information regarding our services on the Service
            and in our advertising on other websites.
          </p>
          <p>
            We cannot and do not guarantee the accuracy or completeness of any information,
            including prices, product images, specifications, availability, and services. We reserve
            the right to change or update information and to correct errors, inaccuracies, or
            omissions at any time without prior notice.
          </p>
        </DetailsCard>

        <DetailsCard title="Prices Policy">
          <p>
            The prices of services are set by the Company and are subject to change without notice.
            We reserve the right to correct any errors or inaccuracies in the prices listed on our
            Service at any time.
          </p>
        </DetailsCard>

        <DetailsCard title="Payments">
          <p>
            All services purchased are subject to a one-time payment. Payment can be made through
            various payment methods we have available, such as Visa, MasterCard, or online payment
            methods (e.g., PayPal).
          </p>
        </DetailsCard>

        <DetailsCard title="Promotions">
          <p>
            Any Promotions made available through the Service may be governed by rules that are
            separate from these Terms. If You participate in any Promotions, please review the
            applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict
            with these Terms, the Promotion rules will apply.
          </p>
        </DetailsCard>

        <DetailsCard title="User Accounts">
          <p>
            When You create an account with Us, You must provide Us information that is accurate,
            complete, and current at all times. Failure to do so constitutes a breach of the Terms,
            which may result in immediate termination of Your account on Our Service.
          </p>
          <p>
            You are responsible for safeguarding the password that You use to access the Service and
            for any activities or actions under Your password, whether Your password is with Our
            Service or a Third-Party Service.
          </p>
          <p>
            You agree not to disclose Your password to any third party. You must notify Us
            immediately upon becoming aware of any breach of security or unauthorized use of Your
            account.
          </p>
          <p>
            You may not use as a username the name of another person or entity or that is not
            lawfully available for use, a name or trademark that is subject to any rights of another
            person or entity other than You without appropriate authorization, or a name that is
            otherwise offensive, vulgar, or obscene.
          </p>
        </DetailsCard>
      </div>
    </div>
  );
};

import { Skeleton } from 'components/Skeleton';
import { classes } from 'utils';

interface IconCardProps {
  icon: string;
  extraClasses?: string;
  iconName?: string;
  iconClasses?: string;
}

const IconCard = ({ icon, iconName = 'icon', extraClasses, iconClasses }: IconCardProps) => {
  return (
    <div
      className={classes(
        'bg-white/[0.08] min-w-10 h-10 rounded-lg [box-shadow:0px_-3px_19px_0px_rgba(0,_0,_0,_0.25)_inset] flex justify-center items-center',
        extraClasses
      )}
    >
      <Skeleton.Loader
        className="h-[18px] w-[18px] !rounded-md  self-center"
        containerClassName="flex"
      >
        <img src={icon} alt={iconName} className={classes('w-[18px] h-[18px]', iconClasses)} />
      </Skeleton.Loader>
    </div>
  );
};

export default IconCard;

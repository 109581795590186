import { Dispatch, SetStateAction } from 'react';

import Dropdown from 'components/common/DropDown';

import { StringToDBValueMapping } from './TaskColumns';

const status = ['all', 'signed', 'processing', 'completed'] as const;

type Status = typeof status[number];

export const GMPFilter = ({
  setPhase,
}: {
  data: any;
  key: string;
  setPhase: Dispatch<SetStateAction<string>>;
}) => {
  const handleOnSelectChange = (value: Status) => {
    setPhase(value === 'all' ? '' : StringToDBValueMapping[value]);
  };

  return (
    <div>
      <span>{'Status: '}</span>
      <Dropdown
        options={status as unknown as string[]}
        onSelect={(value) => handleOnSelectChange(value as Status)}
        withBorder={false}
        classNames={{
          optionContainer: 'w-[160px]',
          option: 'capitalize',
          container: 'w-auto left-1/2 transform -translate-x-1/2',
          selector: 'w-max capitalize !p-0 !h-auto',
        }}
      />
    </div>
  );
};

import NoResultIcon from 'components/Icons/NoResultIcon';

const NoResult = () => {
  return (
    <div className="flex flex-col max-w-[400px] justify-center items-center px-6 py-8 gap-4">
      <NoResultIcon />
      <span className="text-white text-2xl font-medium">No Results Found...</span>
      <span className="text-silver text-center">
        We're sorry, but it looks like we couldn't find what you were looking for.
      </span>
    </div>
  );
};
export default NoResult;
import { ReactNode } from 'react';

import { classNames, classes } from 'utils';

type TableWrapperProps = {
  children: ReactNode;
  classNames?: classNames;
};

const TableWrapper = ({ children, classNames }: TableWrapperProps) => {
  return (
    <div className="text-white min-w-full overflow-hidden overflow-x-auto shadow table-wrap">
      <div
        className={classes(
          'align-middle inline-block min-w-full overflow-hidden',
          classNames?.base
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default TableWrapper;

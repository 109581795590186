import { DEFAULT_PAGE_SIZE } from 'consts';
import { useListValidatorsQuery } from 'gql';
import usePagination from 'hooks/usePagination';

import { useApiAccess } from '../../../contexts/AccessProvider';

export function useValidatorList() {
  const { sessionKey } = useApiAccess();
  const { PageController, setTotalDataCount, pageSize, pageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { data: validatorsData, loading: isLoading } = useListValidatorsQuery({
    variables: {
      page: pageNumber,
      limit: pageSize,
      sessionKey,
    },
    onCompleted(data) {
      setTotalDataCount(data.listValidators?.count || 0);
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    PageController,
    validatorsData: validatorsData?.listValidators?.data || [],
    isLoading,
    pageSize,
  };
}

import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { useListMembersQuery } from 'gql';
import { useSortBy } from 'hooks';
import usePagination from 'hooks/usePagination';

function useChronicleList() {
  const { sessionKey } = useApiAccess();
  const { PageController, setTotalDataCount, pageSize, pageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { sortField, sortOrder, onSort } = useSortBy();

  const { data: membersData, loading: isLoading } = useListMembersQuery({
    variables: {
      sessionKey,
      page: { size: pageSize, number: pageNumber },
      ...(sortField && {
        sort: { field: sortField, order: sortOrder },
      }),
    },
    onCompleted(data) {
      setTotalDataCount(data.listMembers.count);
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    PageController,
    membersData: membersData?.listMembers.data,
    isLoading,
    pageSize,
    sortOrder,
    onSort,
  };
}

export default useChronicleList;

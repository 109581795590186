export const GDPR_CONDITIONS = [
  {
    title: 'Consent: ',
    desc: 'You have given Your consent for processing Personal Data for one or more specific purposes.',
  },
  {
    title: 'Performance of a contract: ',
    desc: 'Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof',
  },
  {
    title: 'Legal obligations: ',
    desc: 'Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.',
  },
  {
    title: 'Vital interests: ',
    desc: 'Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.',
  },
  {
    title: 'Public interests: ',
    desc: 'Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.',
  },
  {
    title: 'Legitimate interests: ',
    desc: 'Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.',
  },
];

export const GDPR_RIGHTS = [
  {
    title: 'Request access to Your Personal Data. ',
    desc: 'The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.',
  },
  {
    title: 'Request correction of the Personal Data that We hold about You. ',
    desc: 'You have the right to have any incomplete or inaccurate information We hold about You corrected.',
  },
  {
    title: 'Object to processing of Your Personal Data. ',
    desc: 'This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.',
  },
  {
    title: 'Request erasure of Your Personal Data. ',
    desc: 'You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.',
  },
  {
    title: 'Request the transfer of Your Personal Data. ',
    desc: 'We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.',
  },
  {
    title: 'Withdraw Your consent. ',
    desc: 'You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.',
  },
];

import { HTMLAttributes } from 'types';

const NoResultIcon = (props: HTMLAttributes<HTMLOrSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="163"
      height="160"
      viewBox="0 0 163 160"
      fill="none"
      {...props}
    >
      <rect
        opacity="0.32"
        x="1.49951"
        y="35.1475"
        width="43.5118"
        height="30.0081"
        rx="5"
        stroke="#A6A3AC"
        strokeWidth="2.77778"
        strokeLinecap="round"
      />
      <path
        opacity="0.32"
        d="M8.35156 51.5928H31.6492M8.35156 58.445H20.7064"
        stroke="#A6A3AC"
        strokeWidth="2.77778"
        strokeLinecap="round"
      />
      <rect
        opacity="0.32"
        x="12.4631"
        y="110.522"
        width="43.5118"
        height="30.0081"
        rx="5"
        stroke="#A6A3AC"
        strokeWidth="2.77778"
        strokeLinecap="round"
      />
      <path
        opacity="0.32"
        d="M19.3153 126.968H42.613M19.3153 133.82H31.6701"
        stroke="#A6A3AC"
        strokeWidth="2.77778"
        strokeLinecap="round"
      />
      <rect
        opacity="0.32"
        x="117.988"
        y="50.2224"
        width="43.5118"
        height="30.0081"
        rx="5"
        stroke="#A6A3AC"
        strokeWidth="2.77778"
        strokeLinecap="round"
      />
      <path
        opacity="0.32"
        d="M124.84 66.6675H148.138M124.84 73.5197H137.195"
        stroke="#A6A3AC"
        strokeWidth="2.77778"
        strokeLinecap="round"
      />
      <rect
        x="59.0587"
        y="66.6677"
        width="43.5118"
        height="30.0081"
        rx="5"
        stroke="white"
        strokeWidth="2.77778"
        strokeLinecap="round"
      />
      <path
        d="M65.8098 83.1719H89.0661M65.8098 88.4233H78.1427"
        stroke="#E563F9"
        strokeWidth="2.77778"
        strokeLinecap="round"
      />
      <path
        opacity="0.48"
        d="M119.005 43.0173C112.017 36.0291 103.597 31.3452 94.6895 28.9657C80.2391 25.1054 64.5055 27.3098 51.5204 35.5787M26.9358 73.5197C25.6318 82.4653 26.5638 91.6741 29.7318 100.244M69.3362 134.475C83.0158 137.372 97.598 134.943 109.764 127.188M126.861 109.837C131.038 103.06 133.588 95.5641 134.509 87.9095"
        stroke="#343436"
        strokeWidth="2.77778"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.225 52.1511C94.0684 35.9946 67.8735 35.9946 51.717 52.1511C35.5605 68.3076 35.5605 94.5024 51.717 110.659C66.6908 125.633 90.2872 126.729 106.523 113.947L129.31 136.734C131.272 138.696 134.452 138.696 136.414 136.734C138.376 134.773 138.376 131.592 136.414 129.631L113.613 106.83C126.289 90.5979 125.16 67.0857 110.225 52.1511ZM58.5004 58.9345C70.9105 46.5243 91.0313 46.5243 103.442 58.9345C115.852 71.3446 115.852 91.4654 103.442 103.876C91.0313 116.286 70.9105 116.286 58.5004 103.876C46.0902 91.4654 46.0902 71.3446 58.5004 58.9345Z"
        fill="#343436"
      />
    </svg>
  );
};

export default NoResultIcon;

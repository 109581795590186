import allImgPaths from 'assets/allImgPaths';
import { SearchType } from 'gql';
import { twMerge } from 'tailwind-merge';

export function classes(...classLists: (string | null | undefined | false)[]) {
  return twMerge(...classLists.map((classList) => (!classList ? null : classList)));
}

export type classNames<T extends string | never = never> = Partial<
  { base?: string } & Record<T, string | undefined>
>;

enum Statuses {
  Completed = 'completed',
  Success = 'success',
  Created = 'created',
  Initiated = 'initiated',
  Initialization = 'initialization',
  Pending = 'pending',
  Failed = 'failed',
  Done = 'done',
  Finalized = 'finalized',
  Unfinalized = 'unfinalized',
}

export const statusColor = (type: string) => {
  if (!type) {
    return;
  }
  type = type.toLowerCase();
  let colors = '';
  switch (type) {
    case Statuses.Completed:
    case Statuses.Finalized:
    case Statuses.Created:
    case Statuses.Success:
      colors = 'bg-[#00CD5E0D] text-[#00CD5E]';
      break;
    case Statuses.Done:
      colors = 'bg-[#3FFF2E12] text-[#3FFF2E]';
      break;

    case Statuses.Initiated:
    case Statuses.Pending:
    case Statuses.Unfinalized:
    case Statuses.Initialization:
      colors = 'bg-[#CDB80012] text-[#CDB800]';
      break;

    case Statuses.Failed:
      colors = 'bg-[#FC383814] text-[#FC3838]';
      break;

    default:
      break;
  }
  return colors;
};

enum ICONS {
  Polygon = 'polygon',
  Binance = 'binance',
  Ethereum = 'ethereum',
  Bitcoin = 'bitcoin',
  Optimism = 'optimism',
  Online = 'online',
  Offline = 'offline',
  Active = 'active',
  Astar = 'astar',
  Arbitrum = 'arbitrum',
}

export const getIcon = (name: string) => {
  name = name?.toLowerCase();
  switch (name) {
    case ICONS.Polygon:
      return allImgPaths.polygonIcon;
    case ICONS.Binance:
      return allImgPaths.binanceIcon;
    case ICONS.Bitcoin:
      return allImgPaths.bitcoinIcon;
    case ICONS.Ethereum:
      return allImgPaths.ethereumIcon;
    case ICONS.Optimism:
      return allImgPaths.optimismIcon;
    case ICONS.Offline:
      return allImgPaths.offlineIcon;
    case ICONS.Online:
      return allImgPaths.onlineIcon;
    case ICONS.Active:
      return allImgPaths.online2Icon;
    case ICONS.Astar:
      return allImgPaths.astarIcon;
    case SearchType.Block:
      return allImgPaths.layerIcon;
    case SearchType.Events:
      return allImgPaths.calendarTickIcon;
    case SearchType.Extrinsics:
      return allImgPaths.dataArrowsIcon;
    case SearchType.Members:
    case SearchType.Accounts:
      return allImgPaths.profileIcon;
    case SearchType.Shards:
      return allImgPaths.hashTagOutlineIcon;
    case SearchType.Tasks:
      return allImgPaths.taskIcon;
    case ICONS.Arbitrum:
      return allImgPaths.arbitrumIcon;
    case 'validators':
      return allImgPaths.verifyIcon;

    default:
      return '';
  }
};

import { useRef, useState } from 'react';

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import allImgPaths from 'assets/allImgPaths';
import { Text, TruncatedText } from 'components';
import { Skeleton } from 'components/Skeleton';
import { FontColors } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { SearchType, useSearchLazyQuery } from 'gql';
import { useOutsideClick } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { getIcon } from 'utils';

import Dropdown from './DropDown';
import NoResult from './NoResult';

const navigationPaths = (id: string) => ({
  [SearchType.Block]: `${routes.block}/${id}`,
  [SearchType.Events]: `${routes.block}/${id?.split(' ')[0]}`,
  [SearchType.Extrinsics]: `${routes.extrinsic}/${id}`,
  [SearchType.Shards]: `${routes.shard}/${id}`,
  [SearchType.Tasks]: `${routes.task}/${id}`,
  [SearchType.Members]: `${routes.chronicle}/${id}`,
  [SearchType.Accounts]: `${routes.account}/${id}`,
  [SearchType.Validators]: `${routes.validator}/${id}`,
});

const SearchOptions: { [key: string]: SearchType } = {
  Blocks: SearchType.Block,
  Events: SearchType.Events,
  Extrinsics: SearchType.Extrinsics,
  Chronicles: SearchType.Members,
  Shards: SearchType.Shards,
  Tasks: SearchType.Tasks,
  Validators: SearchType.Validators,
  Accounts: SearchType.Accounts,
};

export type SearchTypeOptions =
  | 'Blocks'
  | 'Events'
  | 'Extrinsics'
  | 'Chronicles'
  | 'Shards'
  | 'Tasks'
  | 'Validators'
  | 'Accounts';

interface SearchBoxProps {
  defaultSearchType: SearchTypeOptions;
  isLoading?: boolean;
}

interface SearchResultsProps {
  result: { [key: string]: string | number | null }[];
  removeFocus: () => void;
  onNavigate: (_: string) => void;
  isLoading: boolean;
  searchTerm?: string;
}

function SearchResults({
  result,
  removeFocus,
  onNavigate,
  isLoading,
  searchTerm,
}: SearchResultsProps) {
  const impactRef = useRef<HTMLDivElement>(null);

  const outsideClickHandler = (e: { target: { id: string } }) => {
    const targetId = e.target.id || '';

    targetId !== 'search-box' && targetId !== 'search-button' && removeFocus();
  };

  useOutsideClick(impactRef, outsideClickHandler);
  return (
    <div
      ref={impactRef}
      id="search-results"
      className="absolute top-16 w-full z-10 bg-[#010101] border-solid border-[1px] rounded-3xl
      border-[#FFFFFF33]"
    >
      <ComboboxOptions>
        {isLoading ? (
          <Skeleton.Provider isLoading={isLoading}>
            <div className="flex flex-col lg:p-6 p-4 gap-[14px]">
              <div className={`text-sm ${FontColors.Gray}`}>Searching...</div>
              <div className="max-h-96">
                {Array(2)
                  .fill(null)
                  .map((val, index) => (
                    <div
                      key={index}
                      className="border-solid border-l-0 border-r-0 border-b-0 border-t-[1px] border-lightGray text-white flex flex-nowrap py-4 lg:py-[15px] h-auto lg:h-[70px] last:pb-0 items-baseline"
                    >
                      <div className="flex justify-center items-center mb-0 w-auto">
                        <Skeleton.Loader className="bg-white/[0.08] h-10 w-10 rounded-none shadow-[inset_0px_-3px_19px_0px_rgba(0,0,0,0.25)]" />
                      </div>
                      <div className="flex flex-col gap-2 overflow-hidden ml-3 w-full lg:w-auto">
                        <Skeleton.Loader className="w-16 h-4" containerClassName="h-4" />
                        <Skeleton.Loader className="w-40 h-4" containerClassName="h-4" />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Skeleton.Provider>
        ) : result.length > 0 ? (
          <div className="flex flex-col lg:p-6 p-4 gap-[14px]">
            <Text>Search Results</Text>
            <div className="max-h-96 overflow-y-auto">
              <ComboboxOption
                key={searchTerm}
                value={searchTerm}
                className="hidden"
              ></ComboboxOption>
              {result.map((data, index) => {
                const id = data[`${data.__typename}_id`];
                const hash = data[`${data.__typename}_hash`];
                return (
                  <ComboboxOption
                    key={index}
                    value={id}
                    className="border-solid border-l-0 items-baseline border-r-0 border-b-0 border-t-[1px] border-lightGray text-white lg:flex justify-between grid grid-cols-1 lg:grid-cols-2  gap-3 lg:gap-0 py-4 lg:py-[15px] h-[70px] last:pb-0 data-[focus]:bg-zinc-900 data-[focus]:pl-4"
                  >
                    <div
                      className="lg:w-3/4 flex gap-[9px] cursor-pointer"
                      onClick={() => {
                        onNavigate(id as string);
                        removeFocus();
                      }}
                    >
                      <div className="bg-white/[0.08] min-w-10 h-10 rounded-lg [box-shadow:0px_-3px_19px_0px_rgba(0,_0,_0,_0.25)_inset] flex justify-center items-center">
                        <img
                          src={getIcon(data.__typename as string)}
                          alt={'icon'}
                          className="h-[18px] w-[18px]"
                        />
                      </div>
                      <div className="flex justify-center flex-col overflow-hidden flex-1">
                        {id && (
                          <TruncatedText originalText={String(id)} textColor={FontColors.WHITE} />
                        )}
                        {hash && (
                          <TruncatedText
                            originalText={String(hash)}
                            textColor={FontColors.Gray}
                            className="text-xs md:text-sm"
                          />
                        )}
                      </div>
                    </div>
                  </ComboboxOption>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <NoResult />
          </div>
        )}
      </ComboboxOptions>
    </div>
  );
}

function SearchBox({ defaultSearchType, isLoading = false }: SearchBoxProps) {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchType, setSearchType] = useState<SearchType>(SearchOptions[defaultSearchType]);
  const [isFocused, setIsFocused] = useState(false);
  const debounceRef = useRef<number>();
  const navigate = useNavigate();
  const { sessionKey } = useApiAccess();
  const removeFocus = () => {
    setIsFocused(false);
  };
  const [isSearchLoading, setIsSearchingLoading] = useState<boolean>(false);
  const [search, { data: searchResult }] = useSearchLazyQuery();

  const handleSearch = (
    searchTerm: string,
    searchType: SearchType,
    sessionKey: string | undefined
  ) => {
    clearTimeout(debounceRef.current);
    if (searchTerm.length) {
      setIsSearchingLoading(true);
      debounceRef.current = setTimeout(() => {
        search({
          variables: { searchTerm, searchType, sessionKey },
          onCompleted: () => {
            setIsSearchingLoading(false);
          },
        });
      }, 600);
    }
  };

  const handleChange = (value: string) => {
    setSearchTerm(value);
    if (value.length >= 3) {
      const searchText = value.replace('0x', '').replace('#', '');
      handleSearch(searchText, searchType, sessionKey);
    }
  };

  const handleNavigate = (id: string) => {
    navigate(navigationPaths(id?.toString())[searchType]);
  };

  const selectFirstOption = () => {
    if (searchTerm) {
      handleNavigate(searchTerm);
    }
  };

  return (
    <div className="relative flex flex-col gap-4">
      <div className="relative">
        <div className="w-full flex justify-center focus-within:shadow-[0px_0px_0px_1px_rgba(255,255,255,1)] md:h-[48px] h-[40px] shadow-[0px_0px_0px_1px_rgba(255,255,255,0.1)] rounded-[24px] bg-[linear-gradient(90deg,_rgba(255,_255,_255,_0.10)_0%,_rgba(255,_255,_255,_0.00)_100%)] relative">
          <Skeleton.Provider isLoading={isLoading}>
            <Skeleton.Loader className="h-12 !z-20" containerClassName="h-12">
              <Dropdown
                options={Object.keys(SearchOptions)}
                onSelect={(selectedOption) => {
                  if (SearchOptions[selectedOption] !== 'validators') {
                    const searchTermFiltered = searchTerm.replace('0x', '').replace('#', '');
                    search({
                      variables: {
                        searchTerm: searchTermFiltered,
                        searchType: SearchOptions[selectedOption] as SearchType,
                        sessionKey,
                      },
                    });
                  }
                  setSearchType(SearchOptions[selectedOption]);
                }}
                defaultSelected={defaultSearchType}
                withBorder={false}
                classNames={{
                  base: '',
                  container: 'md:w-[133px] mt-3',
                  selector:
                    'w-max h-full md:text-base text-[14px] capitalize gap-[5px] md:pl-6 md:pr-5 pl-4 pr-3',
                  optionContainer: 'py-0 px-5',
                  option: 'py-4',
                }}
              />
              <div className="h-7 w-[1px] bg-[#c7c7c77f] m-auto" />
            </Skeleton.Loader>
            <Skeleton.Loader className="w-10 h-10 rounded-full absolute top-1 right-1 !z-30">
              <Combobox
                onChange={(s: string) => {
                  s && handleNavigate(s);
                }}
              >
                <ComboboxInput
                  type="search"
                  placeholder={`Search for ${Object.keys(SearchOptions).find(
                    (key) => searchType === SearchOptions[key]
                  )}`}
                  tabIndex={1}
                  id="search-box"
                  autoComplete="off"
                  onFocus={() => setIsFocused(true)}
                  onChange={(e) => {
                    const value = e.target.value;
                    const filteredValue = value.replace(/[^a-zA-Z0-9_]/g, ''); // Allow only letters, numbers and underscores
                    handleChange(filteredValue);
                  }}
                  value={searchTerm}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      selectFirstOption();
                    }
                  }}
                  className="flex items-center w-[calc(100%-80px)] md:h-[48px] h-[40px] md:pl-5 pl-3 py-1 pr-10 md:pr-14 leading-10 placeholder:leading-[20px] md:placeholder:leading-[24px] truncate text-white placeholder-[#C7C7C7] placeholder:font-light md:placeholder:text-base placeholder:text-sm search-cancel:h-6 search-cancel:w-6 search-cancel:mt-1 search-cancel:cursor-pointer search-cancel:appearance-none search-cancel:bg-close"
                />

                <ComboboxButton
                  id="search-button"
                  className="flex justify-center items-center md:w-10 md:h-10 w-8 h-8 rounded-full bg-white text-center text-xl leading-0 absolute top-1 right-1 focus:outline-none cursor-default"
                  onFocus={() => setIsFocused(true)}
                  onClick={selectFirstOption}
                >
                  <img
                    src={allImgPaths.searchIcon}
                    className="w-5 h-5 pointer-events-none"
                    alt="search"
                  />
                </ComboboxButton>
                {isFocused && !!searchTerm.trim() && searchTerm.trim().length >= 3 && (
                  <SearchResults
                    removeFocus={removeFocus}
                    result={searchResult?.search || []}
                    isLoading={isSearchLoading}
                    onNavigate={handleNavigate}
                    searchTerm={searchTerm}
                  />
                )}
              </Combobox>
            </Skeleton.Loader>
          </Skeleton.Provider>
        </div>
      </div>
    </div>
  );
}

export default SearchBox;

import { Accordion, Card, Heading } from 'components';
import { FAQ_DATA } from 'consts';

const FaqPage = () => {
  return (
    <div className="max-w-[780px] mx-auto">
      <div className="flex flex-col gap-4 md:gap-8">
        <Heading
          title="FAQ"
          subTitle={
            <div>
              Here are answers to some of the most frequently asked questions (FAQs) that users
              often ask. If you have a question that we haven’t answered here, please don’t hesitate
              to inquire on our
              <a
                className="text-pink cursor-pointer"
                href="https://discord.com/invite/analog"
                target="_blank"
              >
                {' '}
                Discord Server!{' '}
              </a>
              Our technical team is always online and prepared to engage in discussions about new
              ideas and inquiries.
            </div>
          }
        />
      </div>
      <div className="mt-8 md:mt-10">
        <Card extraClasses="rounded-[20px] !py-0 ">
          <Accordion items={FAQ_DATA} />
        </Card>
      </div>
    </div>
  );
};

export default FaqPage;

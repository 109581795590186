import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cn } from '../../utils/classnames';

export type StatusType =
  | 'done'
  | 'completed'
  | 'finalized'
  | 'created'
  | 'success'
  | 'failed'
  | 'initiated'
  | 'pending'
  | 'unfinalized'
  | 'initialization'
  | 'updating';

const badgeVariants = cva(
  'inline-flex items-center rounded-full border px-2.5 py-0.5 text-[10px] text-ellipsis uppercase leading-[18px] transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 w-fit rounded-3xl py-[2px] px-[7px] flex',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-primary text-primary-foreground text-white hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground text-white hover:bg-secondary/80',
        destructive:
          'border-transparent bg-error text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-white',
      },
      status: {
        completed: 'bg-[#00CD5E0D] text-[#00CD5E]',
        finalized: 'bg-[#00CD5E0D] text-[#00CD5E]',
        created: 'bg-[#00CD5E0D] text-[#00CD5E]',
        success: 'bg-[#00CD5E0D] text-[#00CD5E]',
        failed: 'bg-[#FC383814] text-[#FC3838]',
        done: 'bg-[#3FFF2E12] text-[#3FFF2E]',
        initiated: 'bg-[#CDB80012] text-[#CDB800]',
        pending: 'bg-[#CDB80012] text-[#CDB800]',
        unfinalized: 'bg-[#CDB80012] text-[#CDB800]',
        initialization: 'bg-[#CDB80012] text-[#CDB800]',
        updating: 'bg-[#CDB80012] text-[#CDB800]',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  status?: StatusType;
}

function Badge({ className, variant, status, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant, status }), className)} {...props}>
      {status || props.children}
    </div>
  );
}

export { Badge, badgeVariants };

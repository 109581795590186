import { PERSONALDATA_PURPOSES_ONE, PERSONALDATA_PURPOSES_TWO, TRACKING_TECH } from './utils';

export const CollectingUsingPersonalData = () => {
  return (
    <div>
      <h2 className="text-white text-2xl	md:text-[32px] md:leading-[40px]">
        Collecting and Using Your Personal Data
      </h2>
      <h3 className="text-white text-[20px] md:text-6 leading-[28px] mt-3 md:mt-4">
        Types of Data Collected
      </h3>
      <div className="flex flex-col gap-10 mt-3 md:mt-4">
        {/* Personal Data */}
        <div>
          <h3 className="text-white text-xl md:text-[24px] leading-[28px]">Personal Data</h3>
          <p className="my-[10px] md:my-2">
            While using Our Service, We may ask You to provide Us with certain personally
            identifiable information that can be used to contact or identify You. Personally
            identifiable information may include, but is not limited to:
          </p>
          <div className="flex flex-col gap-2">
            <p>First Name</p>
            <p>Last Name</p>
            <p>Telephone number</p>
            <p>Email address</p>
            <p>Wallet address</p>
            <p>Usage Data</p>
          </div>
        </div>

        {/* Usage Data */}
        <div>
          <h3 className="text-white text-xl md:text-[24px] leading-[28px]">Usage Data</h3>
          <p className="mt-[10px] mb-3  md:mb-[10px]">
            Usage Data is collected automatically when using the Service.
          </p>
          <div className="flex flex-col gap-2">
            <p>
              Usage Data may include information such as Your Device's Internet Protocol address
              (e.g. IP address), browser type, browser version, the pages of our Service that You
              visit, the time and date of Your visit, the time spent on those pages, unique device
              identifiers and other diagnostic data.
            </p>
            <p>
              When You access the Service by or through a mobile device, We may collect certain
              information automatically, including, but not limited to, the type of mobile device
              You use, Your mobile device unique ID, the IP address of Your mobile device, Your
              mobile operating system, the type of mobile Internet browser You use, unique device
              identifiers and other diagnostic data.
            </p>
            <p>
              We may also collect information that Your browser sends whenever You visit our Service
              or when You access the Service by or through a mobile device.
            </p>
          </div>
        </div>

        {/* Information from Third-Party Social Media Services */}
        <div>
          <h3 className="text-white text-xl md:text-[24px] leading-[28px] mb-2 md:mb-[10px]">
            Information from Third-Party Social Media Services
          </h3>

          <div className="flex flex-col gap-2">
            <p>
              The Company allows You to create an account and log in to use the Service through the
              following Third-party Social Media Services:
            </p>
            <div className="pl-5">
              <ul className="list-disc">
                <li>Google</li>
                <li>Facebook</li>
                <li>Instagram</li>
                <li>Twitter</li>
                <li>LinkedIn</li>
              </ul>
            </div>
            <p>
              If You decide to register through or otherwise grant us access to a Third-Party Social
              Media Service, We may collect Personal data that is already associated with Your
              Third-Party Social Media Service's account, such as Your name, Your email address,
              Your activities or Your contact list associated with that account.
            </p>
            <p>
              You may also have the option of sharing additional information with the Company
              through Your Third-Party Social Media Service's account. If You choose to provide such
              information and Personal Data, during registration or otherwise, You are giving the
              Company permission to use, share, and store it in a manner consistent with this
              Privacy Policy.
            </p>
          </div>
        </div>

        {/* Tracking Technologies and Cookies */}
        <div>
          <h3 className="text-white text-xl md:text-[24px] leading-[28px] mb-2 md:mb-[10px]">
            Tracking Technologies and Cookies
          </h3>

          <div className="flex flex-col gap-2">
            <p>
              We use Cookies and similar tracking technologies to track the activity on Our Service
              and store certain information. Tracking technologies used are beacons, tags, and
              scripts to collect and track information and to improve and analyze Our Service. The
              technologies We use may include:
            </p>
            <div className="pl-5">
              <ul className="list-disc flex flex-col gap-2">
                <li className="text-white">
                  Cookies or Browser Cookies.
                  <span className="text-offWhite">
                    {' '}
                    A cookie is a small file placed on Your Device. You can instruct Your browser to
                    refuse all Cookies or to indicate when a Cookie is being sent. However, if You
                    do not accept Cookies, You may not be able to use some parts of our Service.
                    Unless you have adjusted Your browser setting so that it will refuse Cookies,
                    our Service may use Cookies.
                  </span>
                </li>
                <li className="text-white">
                  Web Beacons.
                  <span className="text-offWhite">
                    {' '}
                    Certain sections of our Service and our emails may contain small electronic
                    files known as web beacons (also referred to as clear gifs, pixel tags, and
                    single-pixel gifs) that permit the Company, for example, to count users who have
                    visited those pages or opened an email and for other related website statistics
                    (for example, recording the popularity of a certain section and verifying system
                    and server integrity).
                  </span>
                </li>
              </ul>
            </div>
            <p>
              Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your
              personal computer or mobile device when You go offline, while Session Cookies are
              deleted as soon as You close Your web browser. You can learn more about cookies and
              how they work at this{' '}
              <a
                href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies"
                className="text-link cursor-pointer"
              >
                link
              </a>
              .
            </p>
            <p>We use both Session and Persistent Cookies for the purposes set out below:</p>

            <div className="flex flex-col gap-8 mt-6 md:mt-4 pl-0 md:pl-4">
              {TRACKING_TECH.map(({ title, type, administeredBy, purpose }) => {
                return (
                  <div className="flex flex-col gap-2">
                    <div className="pl-5">
                      <ul className="list-disc">
                        <li className="text-white text-lg md:text-xl">{title}</li>
                      </ul>
                    </div>
                    <p>Type: {type}</p>
                    <p>Administered by: {administeredBy}</p>
                    <p>Purpose: {purpose}</p>
                  </div>
                );
              })}
            </div>

            <p className="mt-[6px]">
              For more information about the cookies we use and your choices regarding cookies,
              please visit our Cookies Policy or the Cookies section of our Privacy Policy.
            </p>
          </div>
        </div>

        {/* Use of Your Personal Data */}
        <div>
          <h3 className="text-white text-xl md:text-[24px] leading-[28px] mb-2 md:mb-[10px]">
            Use of Your Personal Data
          </h3>

          <div className="flex flex-col gap-2">
            <p>The Company may use Personal Data for the following purposes:</p>

            <ul className="list-disc flex flex-col gap-2 pl-6 md:pl-8">
              {PERSONALDATA_PURPOSES_ONE.map(({ title, desc }) => {
                return (
                  <li className="text-white">
                    {title} <span className="text-offWhite">{desc}</span>
                  </li>
                );
              })}
            </ul>
            <p className="my-2">
              We may share Your personal information in the following situations:
            </p>
            <ul className="list-disc flex flex-col gap-2 pl-6 md:pl-8">
              {PERSONALDATA_PURPOSES_TWO.map(({ title, desc }) => {
                return (
                  <li className="text-white">
                    {title} <span className="text-offWhite">{desc}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {/* Retention of Your Personal Data */}
        <div>
          <h3 className="text-white text-xl md:text-[24px] leading-[28px] mb-2 md:mb-[10px]">
            Retention of Your Personal Data
          </h3>
          <div className="flex flex-col gap-2">
            <p>
              The Company will retain Your Personal Data only for as long as is necessary for the
              purposes set out in this Privacy Policy. We will retain and use Your Personal Data to
              the extent necessary to comply with our legal obligations (for example, if we are
              required to retain your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
            </p>
            <p>
              The Company will also retain Usage Data for internal analysis purposes. Usage Data is
              generally retained for a shorter period of time, except when this data is used to
              strengthen the security or to improve the functionality of Our Service, or We are
              legally obligated to retain this data for longer time periods.
            </p>
          </div>
        </div>

        {/* Transfer of Your Personal Data */}
        <div>
          <h3 className="text-white text-xl md:text-[24px] leading-[28px] mb-2 md:mb-[10px]">
            Transfer of Your Personal Data
          </h3>
          <div className="flex flex-col gap-2">
            <p>
              Your information, including Personal Data, is processed at the Company's operating
              offices and in any other places where the parties involved in the processing are
              located. It means that this information may be transferred to — and maintained on —
              computers located outside of Your state, province, country or other governmental
              jurisdiction where the data protection laws may differ than those from Your
              jurisdiction.
            </p>
            <p>
              Your consent to this Privacy Policy followed by Your submission of such information
              represents Your agreement to that transfer.
            </p>
            <p>
              The Company will take all steps reasonably necessary to ensure that Your data is
              treated securely and in accordance with this Privacy Policy and no transfer of Your
              Personal Data will take place to an organization or a country unless there are
              adequate controls in place including the security of Your data and other personal
              information.
            </p>
          </div>
        </div>

        {/* Delete Your Personal Data */}
        <div>
          <h3 className="text-white text-xl md:text-[24px] leading-[28px] mb-2 md:mb-[10px]">
            Delete Your Personal Data
          </h3>
          <div className="flex flex-col gap-2">
            <p>
              You have the right to delete or request that We assist in deleting the Personal Data
              that We have collected about You.
            </p>
            <p>
              Our Service may give You the ability to delete certain information about You from
              within the Service.
            </p>
            <p>
              You may update, amend, or delete Your information at any time by signing in to Your
              Account, if you have one, and visiting the account settings section that allows you to
              manage Your personal information. You may also contact Us to request access to,
              correct, or delete any personal information that You have provided to Us.
            </p>
            <p>
              Please note, however, that We may need to retain certain information when we have a
              legal obligation or lawful basis to do so.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectingUsingPersonalData;

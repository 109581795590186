import { Hashicon as EMEHashIcon, HashiconProps } from '@emeraldpay/hashicon-react';
import React from 'react';
import cn from '../../utils/classnames';
import { useTruncate } from '../../utils/truncateString';
import { CopyToClipboard } from '../CopyToClipboard/CopyToClipboard';

export type HashIconProps = {
  size?: HashiconProps['size'];
  value: string | null | undefined;
  className?: string;
  copyable?: boolean;
};

export const HashIcon = React.forwardRef<HTMLDivElement, HashIconProps>(
  ({ className, size, value, copyable }, ref) => {
    return (
      <div ref={ref} className={cn('flex gap-1 text-offWhite items-center', className)}>
        <EMEHashIcon size={size || 24} value={value!} />
        {useTruncate(value!)}
        {copyable && (
          <CopyToClipboard
            iconClasses="inline"
            icon
            id={value as string}
            content={value as string}
          />
        )}
      </div>
    );
  }
);

HashIcon.displayName = 'HashIcon';
